@import "../../styles/utilities";

.single_media_page {
  padding-top: 5.6rem;

  @include mq("ipad") {
    padding-top: 7.5rem;
  }
  .hero_content {
    margin: 4rem 0;
    @include mq("desktop-lesidi") {
      margin-block: 2rem 4rem;
    }
    display: flex;
    flex-direction: row;
    gap: 6rem;
    @include mq("desktop") {
      flex-direction: column;
    }
    > div {
      width: 100%;
    }
    .media-img-holder {
      border-radius: 1rem;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: fill;
        width: 600px;
        height: 600px;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 5/4;
    }

    .text_holder {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .text_content {
        padding-bottom: 2rem;
        padding-top: 5rem;

        h4 {
          font-weight: 700;
          font-size: 2.4rem;
          line-height: 29px;
          letter-spacing: 0.75px;
          color: $color-black-2;
          padding-bottom: 1rem;
        }
        p {
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 2.2rem;
          // letter-spacing: 0.75px;
          color: #727272;
          max-width: 75%;
        }
      }
    }
  }
  .media_content_body {
    margin-top: 7rem;
    @media only screen and (max-width: 600px) {
      margin-top: 4rem;
    }
    .image_text_holder {
      &:nth-child(odd) {
        .row {
          flex-direction: row-reverse;
          @media only screen and (max-width: 600px) {
            flex-direction: column;
          }
        }
      }
      .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2rem;
        @media only screen and (max-width: 600px) {
          flex-direction: column;
        }
        .col {
          width: 40%;
          .cover_holder {
            .media-img-holder {
              height: 100%;
              @media only screen and (max-width: 600px) {
                max-height: 200px;
              }
            }
          }
          @media only screen and (max-width: 600px) {
            width: 100%;
          }
        }
      }
    }
  }

  .slider_holder {
    padding-bottom: 7rem;
    .title_2 {
      font-size: 3.3rem;
      font-weight: 600;
      line-height: 1.35;
      letter-spacing: 0.75px;
      text-transform: capitalize;
      color: #141414;
      margin-bottom: 4rem;
    }
  }

  .media_gallery_holder {
    position: relative;
    padding-top: 75%;
    & > * {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 12px;
      overflow: hidden;
    }
    .ant-image {
      padding: 0px;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        cursor: pointer;
      }
    }
  }
  .slick-arrow {
    width: 3rem;
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 1.4rem 0.7rem;
    border-radius: 3px;
    background-color: $color-white-3;
    transition: 0.2s ease-in-out;
    top: calc(100% + 3.4rem);
    bottom: 0rem;

    svg {
      font-size: 1.7rem;
      color: $color-primary;
      &:lang(ar) {
        transform: scale(-1);
      }
    }

    &::before {
      display: none;
    }

    &.slick-next {
      left: unset;
      right: calc(50% - 3.4rem);
      &:lang(ar) {
        right: unset;
        left: calc(50% - 3.4rem);
      }
    }
    &.slick-prev {
      right: unset;
      left: calc(50% - 3.4rem);
      &:lang(ar) {
        left: unset;
        right: calc(50% - 3.4rem);
      }
    }

    &:hover {
      background-color: $color-primary;
      svg {
        color: $color-white-1;
      }
    }
  }
}
