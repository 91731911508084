@import "../../../styles/utilities";


.single_video {
  position: relative;
  height: 100%;
  background-color: $color-black-2;
  border-radius: 1rem;
  // overflow: hidden;
  width: 300px;
  min-height: 170px;
  max-height: 170px;


  .video_holder {
    // height: 350px;
    height: 100%;
    cursor: pointer;
  }

  .box_floating {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    //
    display: none;

    .box_floating_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 50%;
      padding: 0 1rem 3rem;

      .play_icon {
        font-size: 3.5rem;
        color: $color-white-1;
        border-radius: 50%;
        background-color: $color-primary;
        background-image: radial-gradient(
          $color-primary 50%,
          $color-white-1 50%
        );
        transform: translateY(-50%);
        cursor: pointer;
      }

    }
    &.box_floating_active {
      display: flex;
    }
  }
}
.video_title {
  font-size: 16px;
  font-weight: 600;
  line-height: 2.5rem;
  letter-spacing: 0.75px;
  color: #141414;
  // margin-top: auto;
  margin: 5px 0;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 3; 
  -webkit-box-orient: vertical;
}

.articles 
{
  .single_video{
    min-height: 300px !important;
    max-height: 300px !important;
  }
 
}