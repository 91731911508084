@import "../../../styles/utilities";

.coins-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  > div {
    animation: ticker 100s linear infinite;
    animation-fill-mode: both;
    width: 100%;
    height: fit-content;

    @keyframes ticker {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }

      100% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
      }
    }
    &:hover {
      animation-play-state: paused;
    }
  }
  .coins-card {
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    border-radius: 12px;
    margin-block: 1rem;
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    .ant-card-body {
      &::before,
      &::after {
        content: none;
      }
      height: 100%;
      width: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;
    }
    .coins-card__title {
      font-size: 1.75rem;
      font-weight: 600;
    }
    .coins-card__price-change {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;
      justify-content: space-between;

      .coins-card__price {
        font-size: 1.5rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 0.4rem;
        .icon {
          color: $color-primary-dark;
          display: flex;
          align-items: center;
          &:lang(ar) {
            transform: scale(-1, 1);
          }
        }
        flex-shrink: 0;
      }
      .coins-card__change {
        font-size: 1.5rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 0.4rem;
        .icon {
          transform: scale(-1);
          color: green;
          display: flex;
          align-items: center;
          &:lang(ar) {
            transform: scale(1, -1);
          }
        }
        flex-shrink: 0;
      }
    }
    .ant-card-cover {
      display: flex;
      width: fit-content;
      justify-content: center;
      align-items: center;
      img {
        width: 70px;
        max-height: 100%;
        object-fit: cover;
        border-radius: 0px;
      }
    }
  }
}
