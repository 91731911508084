@import "../utilities";

.kaifa_page {
  .kaifa_page_holder {
    padding-top: 8rem;

    @include mq("ipad") {
      // padding-top: 3rem;
    }
    .image_hero {
      width: 100%;
      height: 40vw;
      background-color: $color-primary;
      // background-image: url("/images/port-container.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .breadcrumb,
  .header_page_box {
    margin-left: 5rem;
    margin-right: 5rem;

    @include mq("ipad") {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

  .kaifa_page_holder {
    .text_social_component_holder {
      padding: 2rem 0 8rem;
    }
    .text_social_component {
      border-radius: 1.5rem;
      box-shadow: 0 12px 42px -4px rgba(69, 69, 69, 0.12);
      background-color: $color-white-1;
      margin: 0 40rem;
      padding: 2rem 2rem 3rem;
      position: relative;
      transform: translate(0);
      overflow: hidden;
      margin-top: -150px;
      width: 773px;
      height: 210px;
      @include mq("ipad") {
        margin: 4rem 1rem;
        padding: 4rem 2rem;
      }

      .img_holder {
        border-radius: 13.1px;
        box-shadow: 0 5.2px 21.9px 0 rgba(69, 69, 69, 0.12),
          2rem 2rem 0 0 $color-primary;
        //   min-width: 23rem;
        // padding: 3rem 1rem;
        // min-width: 180px;
      }

      .text_content {
        h4 {
          font-size: 3.2rem;
          font-weight: 600;
          line-height: 1.13;
          color: $color-black-2;
          margin-bottom: 0.5rem;
        }

        p {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: normal;
          color: $color-gray-2;

          &:not(:last-child) {
            padding-bottom: 2rem;
          }
        }

        > div {
        }
      }

      .social_holder {
        //   min-width: 16rem;
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          li {
            a {
              transition: 0.1s ease-in-out;
              svg {
                background-color: #f5f5f5;
                height: 2.4rem;
                width: 2.4rem;
                border-radius: 50%;
                padding: 0.5rem;
              }

              &:hover {
                color: $color-primary;
              }
            }
          }
        }
      }

      .box_style {
        position: fixed;
        top: 6rem;
        right: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        height: 35rem;
        width: 35rem;
        //   background-color: #d3d7e2;
        transform: translate(50%, 0);

        .bg_color {
          background-color: #fcfcfc;
          // background-color: #e48989;
          border-radius: 50%;
          height: 100%;
          width: 100%;
        }

        .solid_text {
          position: absolute;
          left: 4rem;
          z-index: 9;
          writing-mode: vertical-lr;
          font-size: 3.2rem;
          white-space: nowrap;
        }

        @include mq("ipad") {
          // top: 10rem;
        }

        &:lang(ar) {
          left: 0;
          right: auto !important;
          transform: translate(-50%, 0);
          .solid_text {
            right: 4rem !important;
            left: auto;
          }
        }
      }
    }

    .asma_marine_services {
      flex: 1;
      width: 100%;
      background-color: #f5f5f5;
      margin: 5rem 0;
      padding: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mq("ipad") {
        padding: 5rem 2rem;
      }

      .content_holder {
        width: 100%;
        padding: 5rem 0;
        .title {
          font-size: 4rem;
          font-weight: 600;
          line-height: 1.35;
          letter-spacing: 0.75px;
          text-transform: capitalize;
          color: $color-black-2;
          max-width: 45rem;
        }
        .content {
          margin: 7rem 0;

          .card {
            .img_holder {
              width: 14rem;
              height: 14rem;
              padding: 2rem;
              background-color: $color-primary-dark;
              border-radius: 1.4rem;

              &.brown {
                background-color: #c58e00;
              }
              &.yellow {
                background-color: $color-secondary;
              }
            }

            .text_holder {
              margin-top: 1.6rem;
              h2 {
                font-size: 2rem;
                font-weight: 500;
                letter-spacing: 0.75px;
                color: $color-black-2;
                margin-bottom: 0.8rem;
              }
              p {
                font-size: 1.6rem;
                line-height: normal;
                letter-spacing: 0.75px;
                color: $color-gray-2;
                max-width: 40rem;
              }
            }
          }
        }
      }
    }
  }
}

.title_2{
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.35;
  letter-spacing: 0.75px;
  text-transform: capitalize;
  color: #141414;
  margin-bottom: 7rem;
}
