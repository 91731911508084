@import "../../../styles/utilities";

.tabs_tab_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0rem;
  h4 {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.7rem;
    text-transform: capitalize;
    color: $color-black-2;
  }
  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: capitalize;
    color: $color-gray-2;
    letter-spacing: 0.75px;
  }
}

.active {
  border-color: transparent;
  h4 {
    color: $color-white-1;
  }
  p {
    color: $color-secondary;
  }
}
