@import "../../../styles/utilities";

.button_floating {
  position: fixed;
  bottom: 8rem;
  right: 4rem;
  background-color: $color-white-3;
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;

  &:lang(ar) {
    right: auto;
    left: 4rem;
  }

  svg {
    font-size: 2rem;
    color: $color-primary;
    line-height: 0;
    transition: 0.3s ease-in-out;
  }

  &:hover {
    background-color: $color-primary;
    svg {
      color: $color-white-3;
    }
  }
}
