.ant-pagination {
  text-align: center;
  .ant-pagination-item {
    border: none;
    min-width: 2rem;
    a {
      font-family: $font-primary;
      font-size: 1.8rem;
      color: #bfbfbf;
    }
  }

  .ant-pagination-item-active {
    a {
      color: $color-primary;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border-radius: 1rem;
      border-color: $color-gray-2;

      span {
        svg {
          color: $color-gray-2;
        }
      }
    }
  }

  .ant-pagination-disabled {
  }
}

.ant-tooltip-arrow-content::before {
  background: #ffffff90;
}
