@import "../../../styles/utilities";

.time_display_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;

  font-size: 17px;
  // font-weight: 500;
  color: $color-gray-1;
}
