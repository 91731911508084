@import "../utilities";

.kaifa_page {
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
    overflow: hidden;
  }
  .slick-slide {
    > div {
      height: 100%;
      span {
        width: 100% !important;
      }
      .slide-holder {
        height: 100%;
      }
    }
  }
  .slide-holder {
    // position: relative;
    // img {
    //   width: 100%;
    //   height: 100%;
    //   object-fit: cover;
    // }
  }

  .slick-arrow {
    width: 3rem;
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 1.4rem 0.7rem;
    border-radius: 3px;
    background-color: $color-white-3;
    transition: 0.2s ease-in-out;
    top: unset;
    bottom: 0rem;

    svg {
      font-size: 1.7rem;
      color: $color-primary;
      &:lang(ar){
        transform: scale(-1);
      }
    }

    &::before {
      display: none;
    }

    &.slick-next {
      left: unset;
      right: calc(50% - 3.4rem);
      &:lang(ar){
        right: unset;
        left: calc(50% - 3.4rem);
      }
    }
    &.slick-prev {
      right: unset;
      left: calc(50% - 3.4rem);
      &:lang(ar){
        left: unset;
        right: calc(50% - 3.4rem);
      }
    }

    // &:lang(ar) {
    //   &.slick-next {
    //     right: 0;
    //     left: unset;
    //   }
    //   &.slick-prev {
    //     left: 0;
    //     right: unset;
    //   }
    // }

    &:hover {
      background-color: $color-primary;
      svg {
        color: $color-white-1;
      }
    }
  }

  .slider_holder {
    padding: 14rem 0 18rem;
    .slider-hero {
      min-height: 26rem;
      padding-bottom: 6rem;

      .slide_content_holder {
        padding: 0 1rem;
        .image_holder {
          padding: 0 0rem;
          border-radius: 1rem;
          overflow: hidden;
          min-height: 19rem;
          display: flex;
          flex: 1;
        }
      }
    }
  }
}
