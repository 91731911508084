@import "../../../styles/utilities";

.hic_group_section {
  padding: 10rem 3rem;
  background-color: #f5d200;
  width: 100%;
  height: 100%;

  @include mq("tablet") {
    padding: 7rem 1rem;
  }
  .main_title {
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.23;
    letter-spacing: 0.75px;
    text-transform: capitalize;
    color: $color-black-2;
    padding: 0 2rem;
  }
  .section_content_holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .section_info_holder {
    .section_tilte {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.75px;
      color: #b22222;
      margin-bottom: 40px;
    }
    .info_left_conetnt {
      .section_headline {
        font-weight: 600;
        font-size: 40px;
        letter-spacing: 0.75px;
        color: #141414;
        margin-bottom: 20px;
      }
      .section_p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.75px;
        color: #595959;
        line-height: 24px;
        width: 388.72px;
      }
    }
  }
}
.social_grid {
  display: grid;
  justify-content: center;
  gap: 2rem;
  margin: auto;
  margin-top: 10rem;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 21rem));
  // grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  
  @include mq("desktop-mini") {
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  }
  @include mq("tablet") {
    margin-top: 8rem;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }
}
.social_card_holder {
  background-color: $color-white-1;
  // height: 27.6rem;
  height: 25.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  transition: all ease-in-out 0.3s;
  border-radius: 1.5rem;

  @include mq("tablet") {
    height: 25rem;
  }

  .social_card_icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    & > svg {
      font-size: 7rem;
    }
    .social_text {
      font-size: 2rem;
      font-weight: 400;
      letter-spacing: 0.813844px;
      position: absolute;
      bottom: 0;
      cursor: pointer;
      color: $color-black-1;
      display: flex;
    }
  }
  &.facbook {
    .social_card_icon {
      & > svg {
        color: #1877f2;
      }
    }
    &:hover {
      background-color: #1877f2;
      .social_card_icon {
        & > svg {
          color: $color-white-1;
        }
        p {
          color: $color-white-1;
        }
      }
    }
  }
  &.youtub {
    .social_card_icon {
      & > svg {
        color: #ff0302;
      }
    }
    &:hover {
      background-color: #ff0302;
      .social_card_icon {
        & > svg {
          color: $color-white-1;
        }
        p {
          color: $color-white-1;
        }
      }
    }
  }
  &.linkdin {
    .social_card_icon {
      & > svg {
        color: #0a66c2;
      }
    }
    &:hover {
      background-color: #0a66c2;
      .social_card_icon {
        & > svg {
          color: $color-white-1;
        }
        p {
          color: $color-white-1;
        }
      }
    }
  }
  &.twitter {
    .social_card_icon {
      & > svg {
        color: #1da1f2;
      }
    }
    &:hover {
      background-color: #1da1f2;
      .social_card_icon {
        & > svg {
          color: $color-white-1;
        }
        p {
          color: $color-white-1;
        }
      }
    }
  }
  &.tiktok {
    .social_card_icon {
      & > svg {
        color: #111111;
        filter: drop-shadow(2px 0px 0px #FD3E3E) drop-shadow(-2px -2px 0px #4DE8F4);
      }
    }
    &:hover {
      background-color: #111111;
      .social_card_icon { 
        & > svg {
          color: #FFF;
        }
        p,a {
          color: #FFF; 
        }
      }
    }
  }
  &.insta {
    .social_card_icon {
      & > svg {
        background: radial-gradient(
          circle at 30% 107%,
          #fdf497 0%,
          #fdf497 5%,
          #fd5949 45%,
          #d6249f 60%,
          #285aeb 90%
        );
        border-radius: 18px;
      }
    }
    &:hover {
      background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
      .social_card_icon {
        & > svg {
          color: $color-white-1;
          background: transparent;
        }
        p {
          color: $color-white-1;
        }
      }
    }
  }
}
