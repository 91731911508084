@import "../../../styles/utilities";

.more_videos_wrapper {
  .more_videos_holder {
   
  }

  .title_hero {
    font-weight: 600;
      font-size: 4rem;
    line-height: 5.4rem;
    letter-spacing: 0.75px;
    text-transform: capitalize;
    color: $color-black-2;
  }

  .videos_holder {
    margin: 5rem 0;
    margin-bottom: 20px;
    // height: 350px;
  }
  .videos_holder div {
    // margin-bottom: 20px;
    // height: 350px;
  }
  .SingleVideo_video_holder
  {
    //  height: 350px;
  }

  .pagination_holder {
    margin: 6rem 0;
  }
}
