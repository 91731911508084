@import "../utilities";

.contact_us_wapper {
  padding-top: 8rem;
  background-color: $color-primary;
  background-color: $color-white-1;

  .container {
    // padding: 0 2rem;
  }

  .breadcrumb {
    padding: 2rem;

    svg {
      font-size: 2rem;
    }
  }

  .locations_wrapper {
    margin: 5rem 0 10rem;
    padding: 0 2rem;
    .location_holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      .location {
        background-color: $color-white-2;
        padding: 2rem;
        width: 100%;
        max-width: 45rem;

        .map {
        }
        .title {
          font-size: 1.8rem;
          font-weight: 600;
          color: $color-primary;
          text-transform: capitalize;
          text-align: center;
          padding: 2.5rem 0;
        }
        .description {
          ul {
            li {
              font-size: 1.6rem;
              line-height: 2;
              text-transform: capitalize;
              color: $color-gray-1;
            }
          }
        }
        .link_location {
          a {
            font-size: 2rem;
            font-weight: 500;
            color: $color-black-2;
            text-transform: capitalize;
            display: flex;
            padding-top: 1rem;
            svg {
            }
          }
        }
      }
    }
  }

  // FIND US SECTION
  .find_us {
    padding: 16rem 2rem;
    background-color: $color-secondary;
    .main_title {
      font-size: 4rem;
      font-weight: 600;
      line-height: 1.23;
      letter-spacing: 0.75px;
      text-transform: capitalize;
      color: $color-black-2;
      padding: 0 2rem;
    }

    .social_grid {
      display: grid;
      justify-content: center;
      gap: 2.8rem;
      margin: auto;
      margin-top: 10rem;
      grid-template-columns: repeat(auto-fill, minmax(18rem, 22rem));
    }
    .social_card_holder {
      background-color: $color-white-1;
      height: 27.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem;
      transition: all ease-in-out 0.3s;
      border-radius: 1.5rem;
      .social_card_icon {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        & > svg {
          font-size: 7rem;
        }
        .social_text {
          font-size: 2rem;
          font-weight: 400;
          letter-spacing: 0.813844px;
          position: absolute;
          bottom: 0;
          cursor: pointer;
          color: $color-black-1;
          display: flex;

          svg {
            // font-size: 2rem;
            // color: $color-black-1 !important;
          }
        }
      }
      &.facbook {
        .social_card_icon {
          & > svg {
            color: #1877f2;
          }
        }
        &:hover {
          background-color: #1877f2;
          .social_card_icon {
            & > svg {
              color: $color-white-1;
            }
            p {
              color: $color-white-1;
            }
          }
        }
      }
      &.youtub {
        .social_card_icon {
          & > svg {
            color: #ff0302;
          }
        }
        &:hover {
          background-color: #ff0302;
          .social_card_icon {
            & > svg {
              color: $color-white-1;
            }
            p {
              color: $color-white-1;
            }
          }
        }
      }
      &.linkdin {
        .social_card_icon {
          & > svg {
            color: #0a66c2;
          }
        }
        &:hover {
          background-color: #0a66c2;
          .social_card_icon {
            & > svg {
              color: $color-white-1;
            }
            p {
              color: $color-white-1;
            }
          }
        }
      }
      &.twitter {
        .social_card_icon {
          & > svg {
            color: #1da1f2;
          }
        }
        &:hover {
          background-color: #1da1f2;
          .social_card_icon {
            & > svg {
              color: $color-white-1;
            }
            p {
              color: $color-white-1;
            }
          }
        }
      }
      &.insta {
        .social_card_icon {
          & > svg {
            background: radial-gradient(
              circle at 30% 107%,
              #fdf497 0%,
              #fdf497 5%,
              #fd5949 45%,
              #d6249f 60%,
              #285aeb 90%
            );
            border-radius: 18px;
          }
        }
        &:hover {
          background: radial-gradient(
            circle at 30% 107%,
            #fdf497 0%,
            #fdf497 5%,
            #fd5949 45%,
            #d6249f 60%,
            #285aeb 90%
          );
          .social_card_icon {
            & > svg {
              color: $color-white-1;
              background: transparent;
            }
            p {
              color: $color-white-1;
            }
          }
        }
      }
    }

    @include mq("tablet") {
      padding: 16rem 1rem;
      .main_title {
        padding: 0 2rem;
      }

      .social_grid {
        margin-top: 5rem;
        gap: 1.5rem;
        grid-template-columns: repeat(auto-fill, minmax(18rem, 20rem));
      }
    }
  }
  //
  .footer {
    background-color: $color-primary;
    padding: 2rem;
    p {
      font-size: 1.6rem;
      line-height: 2.06;
      text-align: center;
      color: $color-white-1;
    }
  }
}
