@import "../../../styles/utilities";

.hic_group_section {
  padding: 60px 0 20px;
  width: 100%;
  @include mq("ipad") {
    padding: 30px 0;
  }
  .section_content_holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .section_info_holder {
    .section_tilte {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.75px;
      color: #b22222;
      margin-bottom: 40px;
      // @include mq("ipad") {
      //     margin-bottom: 10px;
      //   }
    }
    .info_left_conetnt {
      .section_headline {
        font-weight: 600;
        font-size: 3rem;
        letter-spacing: 0.75px;
        color: #141414;
        margin-bottom: 20px;
      }
      .section_p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.75px;
        color: #595959;
        line-height: 24px;
        width: 388.72px;
      }
    }
  }
}

