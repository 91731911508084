@import "../../../styles/utilities";

.Sidenav {
  background-image: linear-gradient(to bottom, $color-primary 32%, #861a1a 91%);
  z-index: 555;
  width: 14rem;
  min-height: 100vh;
  padding: 2.4rem 0;
  position: fixed;
  top: 0;

  .content_holder {
    height: 80vh;
    min-height: 50rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    //
    .brand {
    }
    //

    .btn_menu {
      color: $color-white-1;
      min-height: 43px;
      .bars {
        padding: 0.6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.7rem;
        span {
          transition: 0.5s ease-in-out;

          display: inline-block;
          width: 3rem;
          height: 0.2rem;
          background-color: $color-secondary;
        }
      }

      &.btn_close {
        .bars {
          padding: 0.5rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.7rem;

          span {
            &:first-child {
              transform: rotate(225deg) translate(3px, 0px);
            }
            &:last-child {
              transform: rotate(-225deg) translate(-5px, 10px);
            }
          }
        }
      }
    }
    //
    .nav {
      ul {
        li {
          &:not(:last-child) {
            margin-bottom: 2.4rem;
          }
          a {
            svg {
              font-size: 1.4rem;
              color: $color-white-1;
              transition: 0.1s ease-in-out;
              &:hover {
                color: $color-secondary;
                transform: scale(1.3);
              }
            }
          }
        }
      }
    }
    //
    .btn_scroll {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      svg {
        font-size: 3rem;
        color: $color-secondary;
      }
    }
  }

  @include mq("ipad") {
    display: none;
  }
}
