@import "../../../styles/utilities";

.section_vh {
  background-color: $color-white-2;
}
.media_wrapper {
  width: 100%;
  position: relative;
  padding: 6rem 0;
  .bg_color {
    position: absolute;
    top: 50%;
    right: 0%;
    width: 70rem;
    height: 70rem;
    transform: translate(60%, -50%);
    background-color: #f5f5f5;
    border-radius: 50%;
    @media only screen and (max-width: 992px) {
      width: 55rem;
      height: 55rem;
  }
    &:lang(ar) {
      right: auto;
      left: 0;
      transform: translate(-60%, -50%);
    }
  }

  .container {
    padding: 0 2rem;
  }

  .media_content_holder {
    .title {
      font-size: 4rem;
      font-weight: 600;
      line-height: 1.23;
      letter-spacing: 0.75px;
      color: $color-black-2;
      padding-bottom: 5rem;
    }

    .video_holder {
      border-radius: 1.6rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      iframe{
        height: 40vh;
      }
      .play_icon {
        font-size: 5rem;
        color: $color-primary;
        position: absolute;
        cursor: pointer;
      }
    }

    .text_holder {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .text_content {
        padding-bottom: 2rem;

        p {
          font-size: 1.6rem;
          line-height: normal;
          letter-spacing: 0.75px;
          color: $color-gray-2;
          max-width: 80%;
        }

      }
      p.media_article_p{
        font-size: 3.6rem; 
        max-width: 90%;
        color: #000;
        font-weight: 500;
      }
      .go_link {
        align-self: end;
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: 0.75px;
        text-transform: capitalize;
        color: $color-black-2;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 1rem 0.7rem;
        transform-origin: right;
        position: relative;
        z-index: 1;

        &::after {
          content: "";
          width: 50%;
          height: 100%;
          background-color: $color-secondary;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
          transition: all 0.2s ease-in-out;
        }

        &:lang(en) {
          .icon_left {
            display: none;
          }
        }

        &:hover {
          color: $color-primary;
          &::after {
            width: 100%;
          }
        }

        &:lang(ar) {
          .icon_right {
            display: none;
          }
          &::after {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }
}
