@import "../../../../styles/utilities";

.tab_content_holder {
  .title_hero {
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 3.6rem;
    letter-spacing: 0.75px;
    color: $color-black-2;
    text-transform: capitalize;
  }

  .tab_content {
    margin: 3rem 0;
    .tab_content_row {
      display: flex;
      flex-direction: row;
      // flex-wrap: wrap;
      width: 75%;
    /* margin-left: 12rem; */
    margin: 0 auto;
      gap: 30px;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
      }
    }
    .video_holder {
      // width: 100%;
      width: 65%;
      border-radius: 1rem;
      // margin-top: 3rem;
      margin-top: .2rem;
      // overflow: hidden;
      @media screen and (min-width: 1200px) {
        max-width: 625px;
      }

      display: flex;
      align-items: center;
      height: 420px;
      min-height: fit-content;
      @include mq("tablet-wide") {
        justify-content: center;
      }
      @media only screen and (max-width: 600px) {
        height: 30vh;
      }
      .media-img-holder {
        cursor: pointer;
        // height: 100%;
        // width: 100%;
        width: 400px;
        height: 400px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          border-radius: 16px;
        }
      }
      .play_icon {
        font-size: 5rem;
        color: $color-white-1;
        border-radius: 50%;
        background-color: $color-primary;
        background-image: radial-gradient(
          $color-primary 50%,
          $color-white-1 50%
        );
        position: absolute;
        cursor: pointer;
      }
    }

    .text_holder {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .text_content {
        padding-bottom: 1.5rem;
        padding-top: .5rem;
        h4 {
          font-weight: 700;
          font-size: 2.2rem;
          line-height: 25px;
          letter-spacing: 0.75px;
          color: $color-black-2;
          padding-bottom: .5rem;
        }
        p {
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 2.2rem;
          // letter-spacing: 0.75px;
          color: #727272;
          max-width: 90%;
        }
      }
    }
  }

  .more_videos_holder {
    margin-top: 6rem;
    margin-bottom: 22rem;
    @media only screen and (max-width: 600px) {
      margin-top: 4rem;
      margin-bottom: 2rem;
    }
  }

}
