@import "../../styles/utilities";

.media_page {
  .media_page_holder {
    padding: 5rem;
    padding-top: 8rem;
    @include mq("ipad") {
      padding: 9rem 2rem 3rem;
    }
    .header_page_box {
      margin-top: 0px;
      // text-align: center;
    }
  }

  .media_page_content {
  }
}
