@import "../../../styles/utilities";

.places_wrapper {
  margin: 3rem 0;
  padding: 0 3rem;
  .places_inner {
    .places_List {
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(5, 19rem);
  grid-auto-flow: row dense;
  justify-content: center;
  gap: 6rem 5rem;
  max-height: 640px;

  & > *:nth-child(odd) {
    height: 35rem;
    position: relative;
    top: -14rem;
  }
  & > *:nth-child(even) {
    position: relative;
    height: 21rem;
  }

  > :nth-child(1),
  > :nth-child(3),
  > :nth-child(5) {
    top: 0;
  }

  @include mq("desktop") {
    grid-template-columns: repeat(4, 19rem);
    column-gap: 4rem;
    max-height: 1050px;

    > :nth-child(5) {
      top: -14rem;
      order: 9;
    }
    > :nth-child(10) {
      top: -14rem;
      order: 10;
    }
  }
  @include mq("tablet-wide") {
    grid-template-columns: repeat(3, 19rem);
    column-gap: 3rem;
    max-height: 1310px;

    > :nth-child(5) {
      top: -14rem;
      order: 0;
    }
    > :nth-child(8) {
      top: -14rem;
    }
    > :nth-child(10) {
      top: -14rem;
    }
  }
  @include mq("phablet") {
    grid-template-columns: repeat(2, 19rem);
    max-height: 1520px;
    column-gap: 2rem;

    > :nth-child(1) {
      order: 1;
    }
    > :nth-child(2) {
      order: 2;
    }
    > :nth-child(3) {
      top: -14rem;

      order: 4;
    }
    > :nth-child(4) {
      order: 3;
    }
    > :nth-child(5) {
      order: 5;
      top: -14rem;
    }
    > :nth-child(6) {
      order: 6;
      top: -14rem;
    }
    > :nth-child(7) {
      order: 8;
      top: -28rem;
    }
    > :nth-child(8) {
      order: 7;
      top: -14rem;
    }
    > :nth-child(9) {
      order: 9;
      top: -28rem;
    }
    > :nth-child(10) {
      order: 10;
      top: -28rem;
    }
  }
}
