// FIND US SECTION
@import "../../../styles/_utilities";
.find_us {
  padding: 2rem 0 6rem;
  // background-color: $color-secondary;

  .main_title {
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.23;
    letter-spacing: 0.75px;
    text-transform: capitalize;
    color: $color-black-2;
  }

  .social_grid {
    display: grid;
    justify-content: center;
    gap: 2.8rem;
    margin: auto;
    margin-top: 4rem;
    grid-template-columns: repeat(auto-fit, 18rem);
  }
  .social_card_holder {
    background-color: $color-white-1;
    height: 23rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    transition: all ease-in-out 0.3s;
    border-radius: 1.5rem;
    position: relative;
    padding-top: 80px;
    .border_half {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 110px;
      // background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px 10px 110px 110px;
      svg {
        transition: all ease-in-out 0.3s;
        font-size: 60px;
        color: #fff;
      }
    }
    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
    .social_count {
      width: 100px;
      height: 42px;
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      // box-shadow: 0px 4px 12px #1869D2;
      border-radius: 10px;
      font-weight: 600;
      font-size: 25px;
      letter-spacing: 0.75px;
      color: #141414;
    }
    .social_count_txt {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.75px;
      color: #ffffff;
      margin-top: 8px;
      margin-bottom: 4px;
    }
    .social_card_icon {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      & > svg {
        font-size: 7rem;
      }
      .social_text {
        font-size: 2rem;
        font-weight: 400;
        letter-spacing: 0.813844px;
        position: absolute;
        bottom: 0;
        cursor: pointer;
        color: #fff;
        display: flex;

        svg {
          // font-size: 2rem;
          // color: $color-black-1 !important;
        }
      }
    }
    &.facbook {
      background-color: #1877f2;
      &:hover .border_half {
        transition: all 0.3s ease-in-out;
        background-color: #106de4;
      }
      // &:hover {
      //   background-color: #1877f2;
      //   .social_card_icon {
      //     & > svg {
      //       color: $color-white-1;
      //     }
      //     p {
      //       color: $color-white-1;
      //     }
      //   }
      // }
    }
    &.youtub {
      background-color: #ff0302;
      &:hover .border_half {
        transition: all 0.3s ease-in-out;
        background-color: #ee2929;
      }
    }
    &.linkdin {
      background-color: #0a66c2;
      &:hover .border_half {
        transition: all 0.3s ease-in-out;
        background-color: #0d60b3;
      }
    }
    &.twitter {
      background-color: #1da1f2;
      &:hover .border_half {
        transition: all 0.3s ease-in-out;
        background-color: #1794e1;
      }
    }
    &.insta {
      background: linear-gradient(209.17deg, #dc01a5 15.37%, #ff3201 96.16%);
      &:hover .border_half {
        transition: all 0.3s ease-in-out;
        background-color: #d71366;
      }
    }
    &.TikTok {
      background: #010101;
      &:hover .border_half {
        transition: all 0.3s ease-in-out;
        background-color: #111111;
      }
    }
  }

  @include mq("tablet") {
    padding: 16rem 1rem;
    .main_title {
      padding: 0 2rem;
    }

    .social_grid {
      margin-top: 5rem;
      gap: 1.5rem;
      grid-template-columns: repeat(auto-fill, minmax(18rem, 20rem));
    }
  }
}
