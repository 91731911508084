@import "../../../styles/utilities";
.news_letter_wrapper {
  background-color: $color-primary;
  border-radius: 1rem;
  box-shadow: 0 3.9px 9.2px -3.9px rgba(0, 0, 0, 0.12);
  padding: 1rem;
  position: fixed;
  bottom: 16px;
  right: 2rem;
  z-index: 4;
  transition: 0.4s ease-in-out;
  overflow: hidden;
  &:lang(ar) {
    left: 2rem;
    right: auto;
  }

  .news_letter_Holder {
    width: 35rem;
    height: 21rem;
    // display: flex;
    // justify-content: center;
    .news_letter_content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      //   background-color: #999;
      gap: 2rem;
      padding: 0 3rem;
      .header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        h2 {
          font-size: 1.6rem;
          font-weight: 600;
          line-height: 1.38;
          letter-spacing: 0.49px;
          color: #fff;
          max-width: 15rem;
        }
      }

      .form_holder {
        width: 100% !important;
      }
      .form_item {
        .form_input {
          height: 4rem;
          border-radius: 1rem;
          font-size: 1.1rem;
          line-height: normal;
          letter-spacing: 0.49px;
          color: #bfbfbf;
        }
        div {
          color: $color-white-1;
        }
      }
      .form_item_btn {
        margin: 0;

        .btn_submit {
          display: flex;
          align-items: center;
          gap: 0.6rem;
          background-color: $color-secondary;
          border-radius: 0.5rem;
          margin-left: auto;

          span,
          svg {
            font-size: 1.2rem;
            color: $color-primary;
            text-transform: capitalize;
          }

          &:lang(ar) {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .btn_close {
      position: absolute;
      top: 0.5rem;
      left: 1rem;
      padding: 0.5rem;
    }
    .bg_color {
      position: absolute;
      top: 0%;
      left: 0%;
      width: 24rem;
      height: 24rem;
      transform: translate(-50%, -0%);
      background-color: #a01f1f;
      border-radius: 50%;
      z-index: -1;

      &:lang(ar) {
        left: auto;
        right: 0;
        transform: translate(50%, -0%);
      }
    }
  }

  &.hidden {
    transform: translateX(100vw);
    &:lang(ar) {
      transform: translateX(-100vw);
    }
  }
}
