// .single_place {
//   border-radius: 10px;
//   width: 20rem;
//   margin: auto;
//   // height: 38rem;
//   display: flex;
//   flex-direction: column;
//   cursor: pointer;

//   .image_wrapper {
//     position: relative;
//     // height: 40rem;
//     // height: 100%;
//     height: 38rem;
//     width: 20rem;
//     background-position: 50%;
//     background-size: cover;
//     background-repeat: no-repeat;
//     border: solid 4px #fff;
//     border-radius: 10px;
//     // overflow: clip;
//   }

//   .text_wrapper {
//     margin: 0 1rem;
//     position: relative;
//     transform: translateY(-50%);
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     z-index: 1;
//     .title {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       gap: 0.5rem;

//       width: fit-content;
//       min-width: 13rem;
//       margin: auto;
//       padding: 1rem;
//       background-color: #f5f100;
//       border-radius: 3px;

//       span {
//         font-size: 1.4rem;
//         font-weight: 400;
//         text-transform: capitalize;
//         text-align: center;
//         line-height: 1.2;
//         color: #000;
//       }
//     }
//   }

//   &:hover {
//     .image_wrapper::after {
//       transform: scale(1);
//     }
//   }

//   &.small {
//     .image_wrapper {
//       height: 19rem;
//     }
//   }
//   &.small_card {
//     width: unset;
//     height: auto;
//     .image_wrapper {
//       width: 100%;
//       height: 13rem;
//     }
//   }
// }
.single_place {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  border: solid 4px #fff;
  border-radius: 10px;
  transition: all ease-in-out 0.3s;
  &:hover{
    transition: all ease-in-out 0.3s;
    transform: scale(1.04);
  }
  .text_wrapper {
    margin: 0 1rem;
    position: relative;
    transform: translateY(50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      width: fit-content;
      min-width: 13rem;
      margin: auto;
      padding: 1rem;
      background-color: #f5f100;
      border-radius: 3px;

      span {
        font-size: 1.3rem;
        font-weight: 400;
        text-transform: capitalize;
        text-align: center;
        line-height: 1.2;
        color: #000;
      }
    }
  }

  &:hover {
    .image_wrapper::after {
      transform: scale(1);
    }
  }

  &.small {
    .image_wrapper {
      height: 19rem;
    }
  }
  &.small_card {
    width: unset;
    // height: auto;
    .image_wrapper {
      width: 100%;
      height: 13rem;
    }
  }
}
