@import "../../../styles/utilities";

.services_wrapper {
  // background-color: #aaa;
  margin: 4rem 0;
  padding: 2rem 0;
  width: 100%;

  @include mq("ipad") {
    margin: 0;
  }
  .container {
    padding: 0 2rem;
  }

  .services_content_holder {
  }

  .services_content_text {
    position: relative;
    h4 {
      font-size: 4rem;
      font-weight: 600;
      line-height: 1.23;
      letter-spacing: 0.75px;
      color: $color-black-2;
      max-width: 40rem;
      padding-bottom: 1.8rem;
    }

    .solid_txt {
      position: absolute;
      right: 0;
      top: 0;
      max-width: calc(100% - 42rem);
    }

    .row {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 4rem;
      flex-wrap: wrap;

      p {
        font-size: 1.6rem;
        letter-spacing: 0.75px;
        color: $color-gray-1;
        max-width: 40rem;
        line-height: normal;
      }

      .go_link {
        align-self: end;
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: 0.75px;
        text-transform: capitalize;
        color: $color-black-2;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 1rem 0.7rem;
        transform-origin: right;
        position: relative;
        z-index: 1;

        &::after {
          content: "";
          width: 50%;
          height: 100%;
          background-color: $color-secondary;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
          transition: all 0.2s ease-in-out;
        }

        &:lang(en) {
          .icon_left {
            display: none;
          }
        }

        &:hover {
          color: $color-primary;
          &::after {
            width: 100%;
          }
        }

        &:lang(ar) {
          .icon_right {
            display: none;
          }
          &::after {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }

  .slider_holder {
    margin-top: 4rem;
    height: 25rem;
    transition: 0.2s ease-in-out;
    //
    .slide_content_holder {
      //
      .slide_content {
        // border-bottom: 2px solid transparent;
        background-color: $color-white-3;
        background-image: url("../../images/services-img.svg");
        background-repeat: no-repeat;
        background-size: 35% 55%;
        background-position: 95% 50%;
        width: 29rem;
        height: 19rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        border-radius: 0.5rem;
        transition: background-color 0.2s ease-in-out,
          background-position 2s ease-in-out 0.2s;

        &:lang(ar) {
          // align-items: flex-end;
        }

        h5,
        a {
          font-size: 1.5rem;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.64px;
          color: $color-black-2;
          max-width: 14rem;
          padding: 1.3rem;

          transition: 0.1s ease-in-out;
        }
        .more_link {
          align-items: center;
          gap: 0.5rem;
          display: none;
          &:hover {
            color: $color-primary;
          }
        }
        .border {
          height: 0.5rem;
          width: 100%;
          background-color: $color-white-1;
          border-bottom: 2px solid transparent;
        }
      }

      &:hover {
        border-color: $color-primary;
        .slide_content {
          background-color: $color-secondary;
          background-position: 75% 50%;

          .more_link {
            display: flex;
            margin-top: auto;
          }

          .border {
            height: 10px;
            width: 100%;
            border-color: $color-primary;
          }
        }
      }
    }
  }
}
