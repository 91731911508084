@import "../../../styles/utilities";

.apps_slider_holder {
  border-radius: 15px;
  background-color: #fcfcfc;
  margin: 4.8rem 0;

  @include mq("ipad") {
    margin: 4rem 0;
  }
  .image_hero {
    width: 100%;
    height: 40vw;
    background-color: $color-primary;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
