@import "../utilities";

.slick-slider.container {
  max-width: 100%;
  overflow: hidden;
  margin: auto;

  @include mq("xxl", min) {
    width: 1427px;
  }
  @include mq("xxl") {
    width: 1127px;
  }
  @include mq("xl") {
    width: 933px;
  }
  @include mq("lg") {
    width: 723px;
  }
  @include mq("md") {
    width: 100vw;
    padding: 0 1rem;
  }
}
// .slick-slider,
// .slick-list,
// .slick-track,
// .slick-slide {
//   position: static;
//   height: 100%;
// }

.slick-list {
  // margin: 0 -2rem 0 0;
  // background-color: red;
  // margin: 0 0 0 0rem;
}
.slick-slide {
  // background-color: blue;
  & > div {
    // background-color: gold;
    margin: 0 2rem;
  }
}
.slider-hero {
  position: relative;
}

.china_gate_details_header {
  margin: 10rem;
  .slick-arrow {
    width: 4rem;
    height: 4rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 1.4rem 0.7rem;
    border-radius: 50%;
    background-color: #f3f3f3;
    transition: 0.2s ease-in-out;
    top: 50%;

    svg {
      font-size: 2.2rem;
      color: #f5f100 !important;
    }

    &::before {
      display: none;
    }

    &.slick-next {
      right: -8%;
    }
    &.slick-prev {
      left: -8%;
      left: 10%;
    }

    // &:lang(ar) {
    //   &.slick-next {
    //     right: 0;
    //     left: unset;
    //   }
    //   &.slick-prev {
    //     left: 0;
    //     right: unset;
    //   }
    // }

    &:hover {
      background-color: $color-primary;
      svg {
        color: $color-white-1;
      }
    }

    @include mq("tablet") {
      display: none;
    }
  }
}

// .trining-content-wrapper {
//   position: relative;
//   .slick-list {
//     margin: 0 -3rem;
//   }
//   .slick-slide > div {
//     padding: 0 3rem;
//   }

//   .slick-dots {
//     display: flex !important;
//     align-items: center;
//     justify-content: center;
//     gap: 0.7rem;
//     position: absolute !important;
//     bottom: 4.6rem;
//     bottom: 0;
//     max-width: 90%;

//     li {
//       width: 1.4rem;
//       height: 1.4rem;
//       padding: 0;
//       margin: 0;
//       border-radius: 10rem;
//       border: solid 2px #000;
//       // background-color: rgba(95, 31, 100, 0.42);
//       transition: 0.3s linear;

//       button {
//         width: 100%;
//         height: 100%;
//         padding: 0;
//         background-color: transparent;
//         &::before {
//           display: none;
//         }
//       }
//     }
//     .slick-active {
//       // width: 4.9rem;
//       background-color: #000;
//       button {
//       }
//     }
//   }
// }

// .most_trends_wrapper {
//   .slick-arrow {
//     width: 2.9rem;
//     height: 2.9rem;
//     z-index: 10;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     // padding: 1.4rem 0.7rem;
//     border-radius: 50%;
//     background-color: #fff;
//     transition: 0.2s ease-in-out;
//     top: 50%;

//     svg {
//       font-size: 2.2rem;
//       color: #292d32;
//     }

//     &::before {
//       display: none;
//     }

//     &.slick-next {
//       right: 3%;
//     }
//     &.slick-prev {
//       // left: -9%;
//       display: none !important;
//     }

//     // @include mq("lg") {
//     //   display: none !important;
//     // }
//   }
// }
