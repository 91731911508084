@import "../../../styles/utilities";

.footer {
  width: 100%;
  margin-top: auto;
  padding-top: 6rem;
  background-color: $color-primary-dark;

  .container {
    padding: 0 2rem;
  }

  ul.list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // gap: 0.5rem;
    li {
      font-size: 1.6rem;
      line-height: 1.5;
      color: $color-white-1;
    }

    a {
      font-size: 1.6rem;
      text-transform: capitalize;
      line-height: 2.06;
      white-space: nowrap;
      color: $color-white-1;
      transition: 0.2s ease-in-out;

      &:hover {
        color: $color-secondary;
      }
    }

    li.list_title {
      font-size: 1.6rem;
      line-height: 2.06;
      font-weight: 600;
      text-transform: uppercase;
      white-space: nowrap;
      color: $color-secondary;
      flex-basis: 100%;
    }
  }

  ul.download_app {
    .content_holder {
      p {
        text-transform: capitalize;
      }
      ul.list_apps {
        padding: 1rem 0;
        display: flex;
        align-items: center;
        gap: 2.5rem;
      }
    }
  }

  ul.list_news_letter {
    .form_holder {
      padding: 1rem 0;
    }
  }

  .brand {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .img_holder {
    }

    .list.list_follow_us {
      flex-direction: row;
      flex-wrap: nowrap;
      column-gap: 2.2rem;
      li {
        a {
          svg {
            font-size: 1.4rem;
            color: $color-white-1;
            transition: 0.1s ease-in-out;
            &:hover {
              color: $color-secondary;
              transform: scale(1.3);
            }
          }
        }
      }
    }
  }

  //
  .copyright {
    border-top: 1px solid #a01f1f;
    padding: 2rem 0;
    margin-top: 4rem;

    p {
      font-size: 1.6rem;
      line-height: 2.06;
      color: $color-white-1;
    }
  }
}
