@import "../../styles/utilities";

.service_page {
  padding-top: 5.6rem;

  @include mq("ipad") {
    padding-top: 7.5rem;
  }
  .service_page--hero {
    // margin-left: 15rem;
    display: flex;
    flex-direction: row;
    padding-block: 4rem 2rem;
    margin: 0 auto;
    @include mq("desktop-lesidi") {
      padding-block: 2rem 2rem;
      width: 75%;
    }

    gap: 2rem;
    @include mq("desktop") {
      flex-direction: column;
    }
    .service_page--image {
      // overflow: hidden;

      width: 60%;
      aspect-ratio: 5/4;
      @include mq("ipad") {
        max-width: 100%;
      }
      img {
        // height: 100%;
        height: 400px;
        width: 400px;
        border-radius: 16px;
        object-fit: fill;
        @include mq("ipad") {
          height: 100%;
          width: 100%;
     }
      }
     
    }
    .service_page--content {
      width: 100%;
      .service_page--title {
        padding-top: 4rem;
        font-size: 2.4rem;
        font-weight: 600;
        color: #111;
        margin-bottom: 1rem;
      }
      .service_page--title-underline
      {
        margin-bottom: 3rem;
      }
      .service_page--description {
        font-size: 1.8rem;
        font-weight: 400;
        color: #111;
        margin-bottom: 1rem;
      }
    }
  }
  .service_page--gallery {
    padding: 4rem 2rem 8rem;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: unset;
    .service_page--gallery_grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2.5rem;
    }
    h3 {
      width: 100%;
      font-size: 2.4rem;
      font-weight: 600;
      padding-block: 2rem;

      text-align: start;
    }
    .service_page--gallery--images {
      border: 4px solid #fff;
      border-radius: 16px;
      height: 35rem;
      width: 35rem;
      overflow: hidden;
      .ant-image {
        width: 100%;
        height: 100%;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
