@import "../../styles/utilities";

.news_page {
  padding-block: 5.6rem;

  @include mq("ipad") {
    padding-block: 7.5rem;
  }
  .hero_content {
    margin: 3rem 0 0;
    .media-img-holder {
      border-radius: 1rem;
      overflow: hidden;
      img {
        width: 100%;
        max-width: 920px;
        object-fit: cover;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      height: 500px;
      @media only screen and (max-width: 600px) {
        height: 30vh;
      }
    }

    .text_holder {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      justify-content: space-between;

      .text_content {
        width: 100%;
        // max-width: 850px;
        padding-bottom: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        h4 {
          font-weight: 700;
          font-size: 2.8rem;
          line-height: 4.2rem;
          letter-spacing: 0.75px;
          color: $color-black-2;
          padding-bottom: 1rem;
        }
        p {
          font-weight: 400;
          font-size: 2.2rem;
          line-height: 3.3rem;
          letter-spacing: 0.75px;
          color: $color-gray-1;
          max-width: 95%;
          white-space: pre-wrap;
        }
      }
    }
  }
  .news-section--title {
    font-size: 3rem;
  }
}
