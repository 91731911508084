@import "../utilities";

.hic_grop_page {
  .slider_holder {
    padding: 14rem 0 18rem;
    .slider-hero {
      min-height: 26rem;
      padding-bottom: 6rem;

      .slide_content_holder {
        padding: 0 1rem;
        .image_holder {
          padding: 0 0rem;
          border-radius: 1rem;
          overflow: hidden;
          min-height: 19rem;
          display: flex;
          flex: 1;
        }
      }
    }
  }
  .title_2 {
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.35;
    letter-spacing: 0.75px;
    text-transform: capitalize;
    color: #141414;
    margin-bottom: 7rem;
  }
  .hic_grop_page_holder {
    padding-top: 8rem;

    @include mq("ipad") {
      padding-top: 3rem;
    }
  }

  .breadcrumb,
  .header_page_box {
    margin-left: 5rem;
    margin-right: 5rem;

    @include mq("ipad") {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

  .hic_grop_page_content {
    .image_hero {
      width: 100%;
      height: 40vw;
      max-height: 48rem;
      background-color: $color-primary;
      // background-image: url("/images/port-container.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  .text_social_component {
    border-radius: 1.5rem;
    box-shadow: 0 12px 42px -4px rgba(69, 69, 69, 0.12);
    background-color: $color-white-1;
    margin: 0 5rem 2rem 5rem;
    padding: 2rem;
    padding-bottom: 4rem;
    position: relative;
    transform: translateY(-50%);
    overflow: hidden;
    margin-top: -95px;
    width: fit-content;
    @include mq("ipad") {
      margin: 4rem 1rem;
      transform: translateY(0);
    }
    .text_social_component_row {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2rem;
      @include mq("tablet") {
        flex-direction: column;
        gap: 1rem;
      }
    }
    .img_holder {
      border-radius: 13.1px;
      box-shadow: 0 5.2px 21.9px 0 rgba(69, 69, 69, 0.12), 1rem 1rem 0 0 #3eb0c7;
      //   min-width: 23rem;
      // padding: 3rem 1rem;
      img {
        height: 150px;
        width: 150px;
        object-fit: contain;
      }
    }

    .text_content {
      max-width: 500px;
      h4 {
        font-size: 3.2rem;
        font-weight: 600;
        line-height: 1.13;
        color: $color-black-2;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: normal;
        color: $color-gray-2;
        &:not(:last-child) {
          padding-bottom: 2rem;
        }
      }

      > div {
      }
    }

    .social_holder {
      //   min-width: 16rem;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 150px;
        gap: 1rem;
        li {
          a {
            transition: 0.1s ease-in-out;
            svg {
              background-color: #f5f5f5;
              height: 4.4rem;
              width: 4.4rem;
              border-radius: 50%;
              padding: 0.5rem;
            }

            &:hover {
              color: $color-primary;
            }
          }
        }
      }
    }

    .box_style {
      position: fixed;
      top: 7rem;
      right: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      height: 35rem;
      width: 35rem;
      //   background-color: #d3d7e2;
      transform: translate(50%, 0);
      &:lang(ar) {
        left: 0;
        right: auto;
        transform: translate(0, 0);
      }
      .bg_color {
        background-color: #fcfcfc;
        // background-color: #e48989;
        border-radius: 50%;
        height: 100%;
        width: 100%;
      }

      .solid_text {
        position: absolute;
        left: 4rem;
        z-index: 9;
        writing-mode: vertical-lr;
        font-size: 3.2rem;
        white-space: nowrap;
      }

      @include mq("ipad") {
        top: 10rem;
      }
    }
  }

  .asma_marine_services {
    flex: 1;
    width: 100%;
    background-color: #f5f5f5;
    margin: 5rem 0;
    margin-top: 0;
    padding: 5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include mq("ipad") {
      padding: 5rem 2rem;
    }

    .content_holder {
      padding: 5rem 0;
      flex: 1;
      .title {
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.35;
        letter-spacing: 0.75px;
        text-transform: capitalize;
        color: $color-black-2;
      }
      .content {
        margin: 7rem 0;
        .content_row {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
          gap: 20px;
        }
      }
    }
  }

  .contact_hero {
    flex: 1;
    width: 100%;
    min-width: 100vw;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: #f5f5f5;

    .bg_color {
      width: 140vw;
      height: 140vw;
      border-radius: 50%;
      position: absolute;
      bottom: -99vw;
    }

    .contact_content {
      min-width: 50rem;
      padding: 5rem 0;
      h2 {
        font-size: 3.2rem;
        font-weight: 600;
        line-height: 1.13;
        letter-spacing: 0.75px;
        text-align: center;
        color: $color-black-2;
        margin-bottom: 4rem;
      }

      .box_btns {
        padding: 5rem;
        border-radius: 1.5rem;
        box-shadow: 0 12px 42px -4px rgba(69, 69, 69, 0.12);
        background-color: $color-white-1;
        button {
          border: none;
          border-radius: 1rem;
          height: 7.2rem;
          padding: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1.5rem;

          &:not(:last-child) {
            margin-bottom: 1.6rem;
          }
          span,
          svg {
            font-size: 2rem;
            letter-spacing: 0.81px;
            color: $color-black-2;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin-right: 10px !important;
            margin-left: 10px !important;
          }
        }

        .btn_whatsApp {
          background-color: #edfbef;
        }
        .btn_asmsLink {
          background-color: #f7e9e9;
        }
      }
    }
  }
}
