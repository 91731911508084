@import "../../styles/utilities";

.china_gate_details_page {
  padding-top: 5.6rem;

  @include mq("ipad") {
    padding-top: 7.5rem;
  }
  .china_gate_details_inner {
    // padding: 4rem 0;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }
}

.china_gate_details_header {
  // background-image: linear-gradient(
  //     to top right,
  //     rgba(102, 18, 18, 0.6),
  //     rgba(0, 0, 0, 0.2)
  //   ),
  //   url("../../public/images/china-gate-bg.png");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  min-height: calc(100vh - 5.6rem);
  padding: 2rem 0rem 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .side_by_side {
    padding: 0 3rem;

    .text_wrapper {
      // padding: 0 3rem;

      .tooltip_info_wrapper {
        padding: 1rem 0;
        .tooltip_info_inner {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 1.5rem;
          // background-color: #3f3f3f81;
          .tooltip_hero {
            padding: 1px;
            border-radius: 50%;
            font-size: 3.4rem;
            color: #111;
            background-color: #fafafa;
          }
        }
      }
    }
  }
}

.slider_wrapper {
  margin-top: 4rem;
  padding-top: 4rem;
  height: 270px; 

  div[class="slick-list"] {
    max-width: calc(100% - 8rem);
    margin: auto;
    overflow: clip;
  }
  .slide_content_holder {
    height: 27rem;
  }
}

.china_gate_details_header {
  svg[class*="slick-arrow"] {
    width: 4rem;
    height: 4rem;
    transition: 0.2s ease-in-out;
    z-index: 9;
    top: 41%;

    color: #f5f100;

    &::before {
      display: none;
    }

    &:hover {
      color: #b22222;
    }

    @include mq("tablet") {
      display: none !important;
    }
  }

  svg[class*="slick-next"] {
    right: 3px;
  }
  svg[class*="slick-prev"] {
    left: 3px;
  }
}

.btn_show_more {
  width: fit-content;
  margin: auto;
  height: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  span {
    font-size: 2rem;
    text-transform: capitalize;
    color: #f5f100;
  }

  svg {
    font-size: 3.5rem;
    color: #f5f100;
    transition: 0.3s linear;
  }

  &:hover {
    svg {
      animation: scroll 1s infinite;
    }
  }
}

.places_wrapper_holder {
  padding: 8rem 0;
  // background-image: url("../../public/images/china-gate-bg.png");
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
  background-attachment: fixed;
  min-height: calc(100vh - 5.6rem);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mq("ipad") {
    min-height: calc(100vh - 7.5rem);
  }
}

@keyframes scroll {
  from {
    transform: translateY(-4px);
  }
  to {
    transform: translateY(4px);
  }
}
