@import "../../../styles/utilities";

.contact_us_wrapper {
  width: 100%;
  h2 {
    font-weight: 600;
    font-size: 3rem;
    letter-spacing: 0.75px;
    color: #141414;
    margin-bottom: 50px;
    @include mq("ipad") {
      margin-bottom: 40px;
    }
  }

  margin-bottom: 4rem;
  .contact_us_content {
    margin: auto;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    @include mq("tablet") {
      grid-template-columns: 1fr;
    }
    gap: 3rem;
    .contact_us_image {
      width: 100%;
      height: 450px;
      border-radius: 12px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    form {
      padding: 5rem 3rem;
      width: 100%;
      height: 450px;
      border-radius: 12px;
      background: #b22222;

      button {
        height: 42px;
        display: flex;
        width: fit-content;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        background-color: #f5d200;
        font-weight: 500;
        font-size: 18px;
        color: #b22222 !important;
        border: 0;
        svg {
          margin: 0 10px;
          &:lang(ar) {
            transform: rotate(180deg);
          }
        }
        span {
          color: #b22222 !important;
        }
      }
    }
  }
}

.footer {
  background-color: $color-primary-dark;
}
