@import "../../../styles/utilities";

.menu {
  width: calc(100% - 14rem);
  height: 100%;
  transition: 0.7s cubic-bezier(0.8, 0.5, 0.5, 1.1);
  background-color: $color-primary-dark;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 999;

  &:lang(ar) {
    right: auto;
    left: -100%;
  }
  &.menu_visible {
    right: 0;
    &:lang(ar) {
      right: auto;
      left: 0;
    }
  }

  .bg_color {
    position: absolute;
    top: 50%;
    right: 0%;
    width: 70rem;
    height: 70rem;
    transform: translate(60%, -50%);
    background: rgb(245, 210, 0);
    border-radius: 50%;
    z-index: -1;

    &:lang(ar) {
      transform: translate(-60%, -50%);
      right: auto;
      left: 0;
    }
  }

  .nav {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6rem;
      li {
        position: relative;
        a {
          font-size: 2.4rem;
          text-transform: capitalize;
          white-space: nowrap;
          color: $color-white-2;
          padding-bottom: 0.7rem;
          border-bottom: 2px solid transparent;
          transition: 0.2s ease-in-out;

          span {
            font-size: 1.2rem;
            color: $color-white-1;
            position: absolute;
            top: -1.8rem;
            left: -1.5rem;
            transition: 0.2s ease-in-out;
          }

          &:hover {
            color: $color-secondary;
            border-bottom-color: $color-secondary;
            span {
              color: $color-secondary;
            }
          }
        }

        &.img_holder {
          margin: 0 -2rem 0 2rem;

          &:lang(ar) {
            margin: 0 2rem 0 -2rem;
            img {
              transform: rotate(180deg);
            }
          }
        }

        &:last-child a {
          // color: $color-primary;
          &:hover {
            // border-bottom-color: $color-primary;
          }
        }
      }
    }
  }

  @include mq("desktop") {
    .bg_color {
      transform: translate(70%, -50%);
      &:lang(ar) {
        transform: translate(-70%, -50%);
      }
    }
    .nav {
      ul {
        gap: 4rem;
        li a {
          font-size: 2rem;
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
  @include mq("tablet-wide") {
    .nav {
      ul {
        flex-direction: column;
        gap: 6rem;
        align-items: flex-start;
        li {
          a {
            font-size: 2.4rem;
            span {
              font-size: 1.2rem;
              top: -0.5rem;
              left: -2rem;
            }
          }
          &.img_holder {
            display: none;
          }

          &:last-child a {
            color: $color-white-2;
            &:hover {
              color: $color-secondary;
              border-bottom-color: $color-secondary;
            }
          }
        }
      }
    }
  }

  @include mq("ipad") {
    display: none;
  }
}
