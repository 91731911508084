@import "../../../styles/utilities";
.news_card_wrapper {
  display: flex;
  width: 320px;
  @include mq("phone-wide") {
    width: 100%;
  }
  height: 380px;
  padding: 0.5rem;

  &:not(.news_card--mobile) {
    @include mq("tablet", min) {
      width: 500px;
      height: 240px;
    }
    .news_card {
      @include mq("tablet", min) {
        flex-direction: row;
        width: 500px;
        &:lang(ar) {
          flex-direction: row-reverse;
        }
        img {
          width: 200px;
          height: 200px;
        }
      }
      .action {
        justify-content: flex-end;
        &:lang(ar) {
          justify-content: flex-start;
        }
      }
    }
  }
  .news_card {
    padding: 1.5rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .ant-card-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: 100%;
      @include mq("tablet") {
        padding: 0;
      }
      padding-block: 0;
      justify-content: space-between;
      .title {
        height: 100%;
        .ant-card-meta-title {
          font-size: 1.75rem;
          white-space: unset;
        }
        &:lang(ar) {
          text-align: right;
        }
      }
      .action {
        display: flex;
        justify-content: flex-end;
        > span.read_more {
          width: fit-content !important;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    img {
      border-radius: 1rem;
      object-fit: cover;
      background-color: $color-gray-2;
      width: 100%;
      height: 200px;
    }
  }
}
