@import "../../../styles/utilities";

.button_floating {
  position: fixed;
  bottom: 3rem;
  left: 160px;
  background-color: $color-white-3;
  // width: 80px;
  // height: 80px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
  background-color: #FFF;
  z-index: 9;
  border-radius: 60px;
  &:lang(ar) {
    left: auto;
    right: 160px;
  }

  svg {
    font-size: 36px;
    color: #25d366;
    line-height: 0;
    transition: 0.3s ease-in-out;
  }

  @media only screen and (max-width: 992px) {
    left: 20px;
    width: 60px;
    height: 60px;
    &:lang(ar){
      left: auto;
      right: 20px;
    }
}

img{
  width: 70px;
    height: 70px;
}

  // &:hover {
  //   background-color: $color-primary;
  //   svg {
  //     color: $color-white-3;
  //   }
  // }
}
