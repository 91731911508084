@import url("https://fonts.googleapis.com/css2?family=El+Messiri&family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import "./utilities";
@import "./reset";
@import "./custom_antd";
@import "./typography";
@import "./component/SliderHero";
@import "./component/Services";
@import "./component/hic_group";
@import "./component/kaifa";
@import "./component/chinaGateSlider.scss";

html {
  font-size: 62.5%;
  @include mq("tablet") {
    font-size: 55%;
  }
}

body {
  font-family: $font-primary;
  font-size: 100%;
  scroll-behavior: smooth;
}

#__next {
}

.app-wrapper {
  height: 100%;
  min-height: 100vh;
}

.app-main-content {
  width: calc(100% - 14rem);
  margin-left: auto;
  overflow-x: clip;
  // padding-top: 30px;
  &:lang(ar) {
    margin-left: 0;
    margin-right: auto;
  }
  @include mq("ipad") {
    width: 100%;
  }
  // @include mq("tablet", min) {
  //   scroll-snap-type: y mandatory;
  //   overflow-y: scroll;
  //   height: 100vh;
  //   overflow-x: hidden;
  // }
}

// Typography
.btn {
  border: none;
  outline: none;
  background-color: unset;
  cursor: pointer;
  padding: 0;
  font-size: 1.4rem;
  text-transform: capitalize;
}

a {
  color: #000;
}

p {
  white-space: pre-wrap;
}

.ant-dropdown {
  z-index: 9999;
  .ant-dropdown-menu {
    padding: 1rem 0.5rem;
    border-radius: 0.5rem;
    .ant-dropdown-menu-item {
      a {
        font-size: 1.6rem;
        text-transform: capitalize;
        color: $color-gray-1;
        transition: 0.1s ease-in-out;

        &:hover {
          color: $color-black-2;
        }
      }
    }
  }
}

.solid-txt {
  // display: none;
  font-weight: 600;
  font-size: 9.6rem;
  letter-spacing: 0.75px;
  color: black;
  -webkit-text-stroke: 1px #f0f0f0;
  -webkit-text-fill-color: transparent;
  text-align: center;
  z-index: -1;

  &.yellow-text {
    -webkit-text-stroke: 1px #ddbd00;
    // -webkit-text-fill-color: #DDBD00;
    // -webkit-text-stroke: 1px #000;
  }

  &:lang(ar) {
    left: 0;
    right: auto !important;
  }
}

.section-vh {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  scroll-snap-align: start;
}

.input-holder {
  input,
  textarea {
    height: 42px;
    font-weight: 400;
    font-size: 16px;
    color: #141414;
    @include input-placeholder() {
      font-weight: 400;
      font-size: 16px;
      color: #141414;
    }
  }
  textarea {
    height: auto;
  }
}

.PhoneInputCountrySelect {
  option {
    color: #000 !important;
  }
}

// BREADCRUMB
.ant-breadcrumb {
  background-color: transparent;
  display: none;
}
.breadcrumb {
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  margin-bottom: 3rem;

  span {
    display: inline-flex;
    align-items: center;
  }
  svg {
    font-size: 2rem;
  }

  .breadcrumb-to {
    a {
      color: $color-black-2;
      text-transform: capitalize;
      line-height: normal;
      letter-spacing: 0.75px;
    }
  }
  .ant-breadcrumb-separator {
    color: $color-black-2;
  }
  .breadcrumb-current {
    text-transform: capitalize;
    color: $color-primary;
  }
}

.mapouter {
  position: relative;
  text-align: right;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
}
.gm-bundled-control-on-bottom {
  display: none !important;
}

.footer_news_letter_form_holder {
  .ant-form {
    .ant-form-item {
      width: 100%;
      max-width: 25rem;
      margin: 0;

      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            input {
              width: 100%;
              border: none;
              background-color: $color-white-1;
              color: $color-black-1;
              font-size: 1.4rem;
              padding: 1rem 1.5rem;
              border-radius: 0.5rem;

              &::placeholder {
                color: $color-gray-2;
                font-size: 1.2rem;
                text-transform: capitalize;
              }
            }

            .ant-btn {
              display: block;
              margin: 0 0 0 auto;
              padding: 0;
              span {
                font-size: 1.4rem;
                font-weight: 600;
                line-height: 2.36;
                text-transform: uppercase;
                color: $color-secondary;
              }
            }
          }
        }
      }
    }
  }
}

.PhoneInputCountry {
  background: #fff;
  padding-left: 16px;
  padding-right: 8px;
  &:lang(ar) {
    padding-right: 16px;
    padding-left: 8px;
  }
}

.PhoneInputInput {
  border: 0;
  padding: 0 10px;
}

.PhoneInputCountrySelectArrow {
  color: #000;
  opacity: 1;
  font-size: 21px;
  &:lang(ar) {
    margin-left: 0;
    margin-right: 6px;
  }
}

.ticker__element {
  display: flex;
  white-space: nowrap;
}

.slider_hero {
  .slick-slider {
    width: calc(100vw - 140px);
    @include mq("tablet") {
      width: 100%;
    }
  }
  .slick-list {
    width: calc(100vw - 140px);
    @include mq("tablet") {
      width: 100%;
    }
    .slick-slide {
      iframe {
        height: 75vh;
        @include mq("tablet") {
          // height: auto;
          height: 300px;
        }
      }
    }
    .ant-image-img {
      width: calc(100vw - 140px);
      height: 75vh;
      object-fit: contain;
      @include mq("tablet") {
        width: 100%;
        // height: auto;
        height: 300px;
      }
    }
  }
  .aos-init.aos-animate {
    height: 100% !important;
  }

}
h2.aos-animate
{
  text-align: center !important;
}

.contact-us-page-holder {
  .ant-form-item-explain-error {
    color: #fff;
  }
}

#main-header {
  transition: all ease-in-out 0.3s;
  &.active {
    transition: all ease-in-out 0.3s;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

$duration: 20s;
.ticker-wrap {
  width: 100%;
  overflow: hidden;
  height: 4rem;
  padding-left: 100%;
  box-sizing: content-box;

  .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;

    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ticker;
    animation-duration: $duration;

    &:lang(ar) {
      animation-name: tickerAr;
    }

    &__item {
      display: inline-flex;
      align-items: center;

      padding: 0 2rem;
      font-size: 2rem;
      color: white;
      .news_tilte {
        font-weight: 400;
        font-size: 19px;
        color: #141414;
        // height: 22px;
      }
    }
    // &:lang(ar){
    //   padding-left: 100%;
    //   padding-right: 0;
    // }
  }
}

@keyframes ticker {
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes tickerAr {
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.news_divider {
  width: 9.35px;
  height: 9.35px;
  background: #f5d200;
  border-radius: 50%;
  display: block;
  margin: 0 17px;
}

.ant-message-notice-content {
  .ant-message-success .anticon {
    color: #52c41a;
  }
  .ant-message-custom-content {
    span {
      color: #000;
    }
  }
}

.media-img-holder {
  height: 100%;
  .ant-image {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }
  .ant-image-mask {
    border-radius: 1rem;
  }
}

.ant-image-preview-img {
  max-width: 55%;
  object-fit: contain;
}
.title-underline
{
  margin-bottom: 2rem;
  display: inline-block;
  width: 70%;
}
.home-slider-holder {
  margin-top: 5.6rem;
  @include mq("tablet") {
    margin-top: 0;
  }
}

.kaifa-slider-img {
  width: 100%;
  height: 375px;
  object-fit: cover;
}

.kaifa-slider-img-holder {
  .ant-image {
    width: 100%;
    padding: 10px;
    img {
      border-radius: 12px;
      padding: 0px;
    }
  }
}

.rtl-text {
  &:lang(ar) {
    width: fit-content;
    direction: ltr;
  }
}

.state-slider-wrapper {
  .slick-list {
    height: 270px;
    .slick-track {
      height: 100%;
      margin-left: 0;
    }
  }
}

.coins-ship-holder {
  padding-top: 2rem;
  .card-view-container {
    display: flex;
    justify-content: center;
    @include mq("tablet") {
      width: 100%;
    }
  }
  .coins-ship-content {
    > div {
      max-width: 562px;
      width: 100%;
      .china-gate-card--title {
        margin-bottom: 2.5rem;
      }
    }
    display: flex;
    justify-content: center;
    gap: 130px;
    @include mq("desktop-mini") {
      gap: 30px;
    }
    @include mq("tablet") {
      flex-direction: column;
    }
    .card-view-holder {
      align-self: center;
      height: 340px;

      overflow: hidden;
      border-radius: 12px;
      &.card-view-holder__currency {
        padding: 6px 15px;
        box-shadow: inset #0000001a 0px 5px 5px;
        background-color: #fcfcfc;
      }

      box-shadow: #0000001a 0px 4px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      .ant-image {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        object-fit: cover;
      }
    }
  }
  .china-gate-card--title {
    font-weight: 600;
    font-size: 3rem;
    letter-spacing: 0.75px;
    color: #141414;
    margin-bottom: 40px;
    text-transform: capitalize;
    @media only screen and (max-width: 600px) {
      margin-bottom: 20px;
    }
  }
  &.china-gate-card-holder {
    padding-bottom: 10vh;

    .coins-ship-content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
      }
      .card-view-holder {
        cursor: pointer;
        img {
          object-fit: cover;
        }
      }
    }
  }
}

.featured-holder {
  padding-block: 6rem;
  .china-gate-card--title {
    font-weight: 600;
    font-size: 3rem;
    letter-spacing: 0.75px;
    color: #141414;
    margin-bottom: 40px;
    text-transform: capitalize;
    @media only screen and (max-width: 600px) {
      margin-bottom: 20px;
    }
  }
}

.model-unit-wrapper {
  > .ant-modal {
  }
}

.unit-model-body {
  background-color: #fff;
  background-size: cover;
  padding: 5%;
}

////////////////////////////////////// start article style //////////////////////

.article-page {
  padding: 5.6rem 0;
  .article-page-holder {
    padding-top: 20px;
    .articel-hero-holder {
      height: 50vh;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media only screen and (max-width: 600px) {
        height: 30vh;
      }
    }
    .articel-content-holder {
      padding-top: 40px;
      padding-bottom: 30px;
      .article--title {
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 3.6rem;
        letter-spacing: 0.75px;
        color: #141414;
        text-transform: capitalize;
        margin-bottom: 30px;
      }
      .article-desc {
        width: 100%;
        font-size: 16px;
        line-height: 2;
        * {
          font-family: "Poppins" !important;
          &:lang(ar) {
            font-family: "El Messiri" !important;
          }
        }
        strong {
          font-weight: 600 !important;
        }
        p {
          line-height: 1.8 !important;
        }
      }
    }
  }
}

.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  background-color: #b22222;
}

.ant-image-preview-operations {
  background-color: $color-black-2;
}
.ant-image-preview-operations-operation-disabled {
  color: white;
  pointer-events: unset;
}
