@import "../../../styles/utilities";

.main_header {
  height: 5.6rem;
  width: calc(100% - 14rem);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 55;
  &:lang(ar) {
    right: auto;
    left: 0;
  }

  @include mq("ipad") {
    height: 7.5rem;
    width: 100%;
    background-color: $color-primary;
    background-image: linear-gradient(to bottom, #b22222 32%, #861a1a 91%);
  }

  .container {
    padding: 0 2rem;
    height: 100%;
  }

  .header_content {
    height: 100%;
    min-width: 70rem;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .brand {
      display: none;
      a {
        display: block;
        width: 8rem;
      }
    }

    @include mq("ipad") {
      min-width: unset;
      .brand {
        display: block;
      }
    }
  }

  .nav {
    margin: auto;

    ul {
      display: flex;
      align-items: center;
      gap: 3.5rem;

      li {
        a {
          font-size: 1.6rem;
          text-transform: capitalize;
          color: $color-white-1;
          transition: 0.1s ease-in-out;
          white-space: nowrap;
          //
          &.link_with_arrow {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            img {
              width: 1.6rem;
            }
          }

          &:hover {
            color: $color-secondary;
          }
        }
      }
    }

    .link_active {
      position: relative;
      &::after {
        content: "";
        display: inline-block;
        border-top-left-radius: 5rem;
        border-top-right-radius: 5rem;
        border: 1rem solid $color-primary-dark;
        border-bottom: 0;
        position: absolute;
        top: 3rem;
        right: 50%;
        transform: translateX(50%);
      }
    }

    @include mq("ipad") {
      display: none;
    }
  }

  .menu_lang_holder {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.4rem;

    &:lang(ar) {
      margin: 0 auto 0 0;
    }

    .btn_lang {
      font-size: 1.6rem;
      text-transform: uppercase;
      color: $color-white-1;
      border-bottom: 2px solid transparent;
      transition: all 0.3s ease-in-out;
      border-radius: 0;
      &_ar {
        font-family: $font-primary-ar;
        font-size: 2rem;
        font-weight: 300;
      }

      &:hover {
        color: $color-secondary;
      }

      &_active {
        border-bottom-color: $color-white-1;
      }
    }
    .separator {
      width: 1.5px;
      height: 1.5rem;
      background-color: $color-white-1;
    }
  }

  .toggle_menu {
    line-height: 0;
    color: $color-white-1;
    font-size: 3rem;
    margin-left: 2rem;
    display: none;
    transition: 0.2s ease-in-out;
    :lang(ar){
      margin-left: 0;
      margin-right: 2rem;
    }

    &:hover {
      color: $color-secondary;
    }

    @include mq("ipad") {
      display: inline-block;
    }
  }

  @include mq("ipad") {
    .nav {
      display: block;
      position: fixed;
      top: 7.5rem;
      height: 100vh;
      right: 0;
      left: 5rem;
      background-color: rgba(0, 0, 0, 0.95);
      padding: 4rem 5rem;
      transform: translateX(100%);
      transition: transform 0.4s ease-in-out;

      &:lang(ar) {
        right: 14rem;
        left: 0;
        transform: translateX(-100%);
      }

      ul {
        flex-direction: column;
        align-items: flex-end;

        a {
          &.link_with_arrow {
            flex-direction: row-reverse;
          }
        }

        .link_active {
          &::after {
            content: "";
            display: inline-block;
            border-top-left-radius: 5rem;
            border-top-right-radius: 5rem;
            border: 1rem solid $color-primary;
            border-bottom: 0;
            position: absolute;
            top: 0rem;
            right: -3rem;
            transform: translate(0, 50%) rotate(-90deg);
          }
          &:lang(ar) {
            &::after {
              right: auto;
              left: -3rem;
              transform: translate(0, 50%) rotate(90deg);
            }
          }
        }
      }
    }
    .menu_active {
      .nav {
        transform: translateX(0);
      }
    }
  }
}
