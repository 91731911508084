/************ VARIABLES ********************/
// FONTS
$font-primary: "Poppins", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$font-primary-ar: "El Messiri", "Poppins", -apple-system, BlinkMacSystemFont,
  Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
  Helvetica Neue, sans-serif;

// COLORS
$color-primary: #b22222;
$color-primary-dark: #a01f1f;
$color-secondary: #f5d200;
$color-white-1: #ffffff;
$color-white-2: #fcfcfc;
$color-white-3: #f5f5f5;
$color-black-1: #000000;
$color-black-2: #141414;
$color-gray-1: #595959;
$color-gray-2: #8c8c8c;

/************MEDIA QUERY ********************/
// $breakpoints: (
//   "xs-phone": 360px,
//   "phone": 400px,
//   "phone-wide": 480px,
//   "phablet": 576px,
//   "tablet-small": 640px,
//   "tablet": 768px,
//   "ipad": 992px,
//   "tablet-wide": 1024px,
//   "desktop": 1300px,
//   "desktop-lg": 1600px,
//   "desktop-wide": 1600px,
//   "desktop-lesidi": 1920px,
// );
$breakpoints: (
  "xs-phone": 361px,
  "phone": 401px,
  "phone-wide": 481px,
  "phablet": 577px,
  "tablet-small": 641px,
  "tablet": 769px,
  "ipad": 993px,
  "tablet-wide": 1025px,
  "desktop": 1301px,
  "desktop-mini": 1460px,
  "desktop-lg": 1601px,
  "desktop-wide": 1600px,
  "desktop-lesidi": 1920px,
);

@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type==max {
      $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

/*****************prfix*************/
@mixin prefixer($property, $val) {
  #{$property}: $val;
  -webkit-#{$property}: $val;
  -moz-#{$property}: $val;
  -ms-#{$property}: $val;
  -o-#{$property}: $val;
}

/*****************input placeholder*************/
@mixin input-placeholder {
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}
