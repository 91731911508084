.header_page_box {
  background-color: #c58e00;
  border-radius: 1.5rem;
  padding: 1.8rem 2.4rem;
  margin: 3rem 0;

  h2 {
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 3.6rem;
    text-transform: capitalize;

    @include mq("tablet") {
      font-size: 2.8rem;
    }
  }
}

.media_page_content {
  .tabs_holder {
    .ant-tabs {
      .ant-tabs-nav-wrap {
        justify-content: center;
        align-items: center;
        padding: 2rem 0;

        .ant-tabs-nav-list {
          width: 890px;
          gap: 1.4rem 2.4rem;
          justify-content: flex-start;
          justify-content: center;
          flex-wrap: wrap;

          @include mq("ipad") {
            gap: 1.4rem 2rem;
          }
        }
        .ant-tabs-tab {
          min-width: 18.2rem;
          min-height: 7.5rem;
          background-color: $color-white-2;
          border-radius: 1rem;
          border: 1px solid #f0f0f0;
          padding: 0.8rem;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all ease-in-out 0.5s;
          //   background: #e5e5e5;

          //   .ant-tabs-tab-btn {
          //     font-size: 1.4rem;
          //     font-weight: 500;
          //     text-transform: capitalize;
          //     color: $color-primary;
          //     background-color: #c58e00;
          //   }

          &.ant-tabs-tab-active {
            background-color: $color-primary;
            h4 {
              color: #fff;
            }
            p {
              color: #f5d200;
            }
            // .ant-tabs-tab-btn {
            //   color: $color-white-1;
            // }
          }
        }

        @include mq("ipad") {
          justify-content: center;
        }
      }
      //
      .ant-tabs-ink-bar-animated,
      .ant-tabs-nav::before {
        display: none !important;
      }
    }
  }
}

.china_gate_title {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  span {
    font-weight: 300;
    font-size: 10rem;
    text-transform: uppercase;
    color: #fff;
    padding: 0.7rem 0;
  }
  .underline {
    // text-decoration: 4px underline;
    border-bottom: 4px solid #fff;
  }
  .bold {
    font-weight: 900;
  }
  &:lang(ar) {
    direction: ltr;
    justify-content: flex-end;
    .bold {
      font-weight: 500;
    }
  }
}

.place_descraption_style {
  margin: 3rem 0;
  // max-width: 43rem;
  .head {
    padding-left: 2rem;
    margin-bottom: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &::before {
      content: "";
      display: inline-block;
      height: 100%;
      width: 0.7rem;
      background-color: #f5f100;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:lang(ar) {
      padding-left: 0;
      padding-right: 2rem;
      &::before {
        left: auto;
        right: 0;
      }
    }
    span {
      font-size: 2rem;
      line-height: 1.2;
      color: #fff;
      font-weight: 500;
    }

    .color {
      font-size: 3rem;
      color: #f5f100;
    }
    .upper {
      text-transform: uppercase;
    }
    .capitalize {
      text-transform: capitalize;
    }
  }
  .desc {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.4;
    color: #fff;
    padding-left: 2rem;
    max-width: 60rem;
    &:lang(ar) {
      line-height: 1.6;
    }
  }
}

.arrow-slider {
  font-size: 2.5rem;
  color: #f5f100 !important;
}

.ADS-place {
  border: solid 4px #fff;
  border-radius: 10px;
  height: 20rem;
  width: 65rem;
  max-width: 100%;
  margin: auto;
  overflow: auto;
  display: block;
  img {
    height: 100%;
    object-fit: cover;
  }
  &.ADS-place-home {
    margin-top: 2rem;
    width: 100%;
    height: 300px;
    @include mq("ipad") {
      height: auto;
    }
  }
}
