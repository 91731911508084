@import "../../styles/_utilities";

.slider_hero {
  // height: 80vw;
  // max-height: 78rem;

  background-color: rgba($color-black-2, 0.8);
  // background: linear-gradient(
  //   45deg,
  //   rgba(56, 56, 56, 1) 30%,
  //   rgba(178, 34, 34, 1) 70%
  // );
  // background: linear-gradient(
  //   285deg,
  //   rgba(178, 34, 34, 1) 50%,
  //   rgba(56, 56, 56, 1) 50%
  // );

  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;

  @include mq("ipad") {
    height: auto;
    margin-top: 7.5rem;
  }
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    // height: 100%;
    overflow: hidden;
  }
  .slick-slide {
    > div {
      height: 100%;
      span {
        width: 100% !important;
      }
      .slide-holder {
        height: 100%;
      }
    }
  }
  .slide-holder {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .slick-arrow {
    width: max-content;
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 1.4rem 0.7rem;
    border-radius: 3px;
    background-color: $color-white-1;
    transition: 0.2s ease-in-out;

    svg {
      font-size: 1.7rem;
      color: $color-primary;
    }

    &::before {
      display: none;
    }

    &.slick-next {
      right: 5rem;
      top: calc(50% - 3.5rem);
    }
    &.slick-prev {
      left: unset;
      right: 5rem;
      top: unset;
      bottom: calc(50% - 3.5rem);
    }

    &:hover {
      background-color: $color-primary;
      svg {
        color: $color-white-1;
      }
    }
  }
}
