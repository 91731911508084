@import "../../../styles/utilities";

.card_holder {
  .card {
    // background-color: red;
    width:100%;
    max-width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding: 2rem;
    cursor: pointer;
    transition: 0.2s ease-out;
    &:hover {
      transform: translateY(-5px);
    }
    .img_holder {
      width: 240px;
      height: 240px;
      overflow: hidden;
      background-color: #fff;
      border-radius: 1.4rem;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      &.brown {
        background-color: #c58e00;
      }
      &.yellow {
        background-color: $color-secondary;
      }
      img {
        height: 100%;
        object-fit: contain;
      }
    }

    .text_holder {
      margin-top: 1.2rem;
      h2 {
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: 0.75px;
        color: $color-black-2;
        margin-bottom: 0.8rem;
        text-align: center;
        line-height: 1.4;
        word-break: break-word;
        &:lang(ar){
          letter-spacing: normal;
        }
      }
      p {
        font-size: 1.6rem;
        line-height: normal;
        letter-spacing: 0.75px;
        color: $color-gray-2;
        max-width: 40rem;
      }
    }
  }
}

.brand_modal_content {
  background-color: #fff;
  border-radius: 1rem;
  padding: 5rem 3rem;
  margin: auto;

  .modal_body {
    .image_holder {
      display: flex;
      justify-content: center;
      border-radius: 1rem;
      overflow: hidden;
      box-shadow: 0.7rem 0.7rem 0.5rem 0px rgba(0, 0, 0, 0.1);
    }
    .text_holder {
      .title {
        font-size: 2.4rem;
        font-weight: 600;
        text-transform: capitalize;
        color: #b22222;
        margin-bottom: 1.5rem;
      }
      .desc {
        p {
          font-size: 1.4rem;
          font-weight: 500;
          text-transform: capitalize;
          line-height: 1.6;
          color: #535353;
        }
      }
    }
  }

  .modal_footer {
    margin-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    .btn_ok {
      min-width: 20rem;
      height: 4.6rem;
      padding: 1rem 2rem;
      background-color: #f5d200;
      border-radius: 1rem;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 2.2rem;
        font-weight: 600;
        text-transform: capitalize;
        color: #fff;
      }
    }
  }
}
