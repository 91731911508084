@import "../../../styles/utilities";
.news_ticker {
  display: flex;
  align-items: center;
  margin-bottom: 6rem;
      position: relative;
    z-index: 4;
    background-color: #F5F5F5;
  @include mq("tablet") {
    flex-direction: column;
  }
}
.news_ticker_header {
  width: 232.84px;
  height: 90px;
  display: flex;
  align-items: center;
  background-color: #f5d200;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  padding: 20px 30px;
  color: #000;
  @include mq("tablet") {
    width: 100%;
    justify-content: center;
    height: 80px;
    font-size: 26px;
  }
}

.news_ticker_content {
  flex: 1;

  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;


  .news_tilte {
    font-weight: 400;
    font-size: 16px;
    color: #141414;
    height: 22px;
  }
  .news_divider {
    width: 9.35px;
    height: 9.35px;
    background: #f5d200;
    border-radius: 50%;
    display: block;
    margin: 0 17px;
  }
  @include mq("tablet") {
    width: 100%;
  }
}

.single_news_holder {
  display: flex;
  align-items: center;
  height: 22px;
  display: inline-block;
  padding-left: 100%;
  animation: marquee 5s linear infinite;
  @include mq("tablet") {
    height: auto;
    margin-top: 16px;
  }
}


/* Make it move */
@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}