.services_wrapper {
  // height: 80vw;
  // max-height: 78rem;

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
    overflow: hidden;
  }
  .slick-slide {
    > div {
      height: 100%;
      span {
        width: 100% !important;
      }
      .slide-holder {
        height: 100%;
      }
    }
  }
  .slide-holder {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .slick-arrow {
    width: max-content;
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 1.4rem 0.7rem;
    border-radius: 3px;
    background-color: $color-white-3;
    transition: 0.2s ease-in-out;
    top: unset;

    svg {
      font-size: 1.7rem;
      color: $color-primary;
    }

    &::before {
      display: none;
    }

    &.slick-next {
      right: unset;
      left: 9rem;
      bottom: 0rem;
    }
    &.slick-prev {
      left: 5rem;
      bottom: 0;
    }

    &:lang(ar) {
      &.slick-next {
        right: 5rem;
        left: unset;
      }
      &.slick-prev {
        left: unset;
        right: 9rem;
      }
    }

    &:hover {
      background-color: $color-primary;
      svg {
        color: $color-white-1;
      }
    }
  }
}
