.service_preview_wrapper {
  position: relative;
  padding: 0 2rem;
  width: 100%;
  overflow: clip;
  height: 35rem;
  // display: flex;
  // align-items: center;
  .img_holder {
    position: relative;
    height: 100%;
    width: 100%;
    border: solid 4px #fff;
    border-radius: 10px;
    overflow: clip;

    div[class*="ant-image"] {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    color: rgba(0, 0, 0, 0.596);
  }

  .text_holder {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 2.4rem;

    // height: 30rem;

    overflow: auto;
    .content {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // background-color: red;
      // max-width: 33rem;

      .name {
        font-weight: 700;
        font-size: 4rem;
        text-transform: capitalize;
        line-height: 1.2;
        color: #fff;
        // max-width: 30rem;
        padding: 0 2rem;
      }
      .disc {
        padding: 1rem 2rem;
        margin-top: 3rem;
        background-color: rgba(0, 0, 0, 0.4);
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0)
        );

        display: flex;
        flex-direction: column;

        span {
          font-size: 1.8rem;
          font-weight: 600;
          text-transform: capitalize;
          line-height: 1.2;
          color: #f5f100;
        }
        .about {
          font-size: 1.4rem;
          font-weight: 400;
          text-transform: none;
          line-height: 1.4;
          margin-top: 1rem;
        }
      }
    }
    .footer {
      flex: 1;
      padding: 1rem 2rem 0;
      display: flex;
      .list {
        margin-top: auto;
        display: flex;
        align-items: center;
        gap: 2rem;

        li {
          svg {
            font-size: 2.4rem;
            color: #fff;
          }
        }
      }
    }
  }
}
