@import "../../../styles/utilities";

.news-section {
  padding-top: 6rem;
  .news-section--title {
    font-weight: 600;
    font-size: 3rem;
    letter-spacing: 0.75px;
    color: #141414;
    margin-bottom: 40px;
    text-transform: capitalize;
  }
  .time_display_container{
    color: #b22222;
    margin-top: 4rem;
  }
  .apps_slider_holder {
    .slick-track {
      height: 100%;
      display: flex;
    }
    .slick-list,
    .slick-slide,
    .slick-slider {
      height: 100%;
    }
    .slick-slide {
      > div {
        width: 100%;
        margin: 0;
      }
    }
  }
  .news-section--world {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    @include mq("ipad") {
      grid-template-columns: 1fr 1fr;
    }
    @include mq("phablet") {
      grid-template-columns: 1fr;
    }

    .news_card_wrapper {
      width: 100%;
      .ant-card {
        width: 100%;
      }
      .news_card {
        height: 100%;
        .ant-card-body {
          padding: 0;
          .title {
            .ant-card-meta-title {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  .news-section--title_holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .go_link_holder {
    display: flex;
    justify-content: flex-end;
    .go_link {
      width: fit-content;
      margin-top: 2rem;
      align-self: end;
      font-size: 1.8rem;
      font-weight: 500;
      letter-spacing: 0.75px;
      text-transform: capitalize;
      color: $color-black-2;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem 0.7rem;
      transform-origin: right;
      position: relative;
      z-index: 1;

      &::after {
        content: "";
        width: 50%;
        height: 100%;
        background-color: $color-secondary;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        transition: all 0.2s ease-in-out;
      }

      &:lang(en) {
        .icon_left {
          display: none;
        }
      }

      &:hover {
        color: $color-primary;
        &::after {
          width: 100%;
        }
      }

      &:lang(ar) {
        .icon_right {
          display: none;
        }
        &::after {
          left: unset;
          right: 0;
        }
      }
    }
  }
}
