@import "../../../styles/utilities";

.featured_media {
  .featured_media_holder {
    position: relative;
    padding-top: 75%;
    & > * {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 12px;
      overflow: hidden;
    }
    .ant-image {
      padding: 0px;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        cursor: pointer;
      }
    }
  }
  .slick-arrow {
    width: 3rem;
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 1.4rem 0.7rem;
    border-radius: 3px;
    background-color: $color-white-3;
    transition: 0.2s ease-in-out;
    top: calc(100% + 3.4rem);
    bottom: 0rem;

    svg {
      font-size: 1.7rem;
      color: $color-primary;
      &:lang(ar) {
        transform: scale(-1);
      }
    }

    &::before {
      display: none;
    }

    &.slick-next {
      left: unset;
      right: calc(50% - 3.4rem);
      &:lang(ar) {
        right: unset;
        left: calc(50% - 3.4rem);
      }
    }
    &.slick-prev {
      right: unset;
      left: calc(50% - 3.4rem);
      &:lang(ar) {
        left: unset;
        right: calc(50% - 3.4rem);
      }
    }

    &:hover {
      background-color: $color-primary;
      svg {
        color: $color-white-1;
      }
    }
  }
}
