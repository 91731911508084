@import "../utilities";

.about_page {
  .about_page_holder {
    padding-top: 8rem;

    @include mq("desktop") {
      padding-top: 3rem;
    }
  }

  .breadcrumb,
  .header_page_box {
    margin-left: 5rem;
    margin-right: 5rem;

    @include mq("desktop") {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

  .about_page_holder {
    .text_social_component_holder {
      //   padding: 2rem 0 8rem;
      padding-bottom: 8rem;
    }
    .text_social_component {
      border-radius: 1.5rem;
      box-shadow: 0 12px 42px -4px rgba(69, 69, 69, 0.12);
      background-color: $color-white-1;
      margin: 0 5rem;
      padding: 4rem 4rem 6rem;
      position: relative;
      transform: translate(0);
      overflow: hidden;

      @include mq("desktop") {
        margin: 4rem 1rem;
        padding: 4rem 2rem;
      }

      .img_holder_style {
        border-radius: 1.5rem;
        box-shadow: 0 5.2px 21.9px 0 rgba(69, 69, 69, 0.12),
          2rem 2rem 0 0 $color-primary;
        display: flex;
        align-items: center;
        width: 22.6rem;
        // height: 32.3rem;
        background-color: #f5f5f5;
        overflow: hidden;
        & > span {
          width: 100% !important;
        }
      }

      .text_content {
        h4 {
          font-size: 3.2rem;
          font-weight: 600;
          line-height: 1.13;
          color: $color-black-2;
          margin-bottom: 0.5rem;
        }

        p {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: normal;
          color: $color-gray-2;

          &:not(:last-child) {
            padding-bottom: 2rem;
          }
        }

        > div {
        }
      }

      .social_holder {
        //   min-width: 16rem;
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          li {
            a {
              transition: 0.1s ease-in-out;
              svg {
                background-color: #f5f5f5;
                height: 2.4rem;
                width: 2.4rem;
                border-radius: 50%;
                padding: 0.5rem;
              }

              &:hover {
                color: $color-primary;
              }
            }
          }
        }
      }

      .box_style {
        position: fixed;
        top: 6rem;
        right: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        height: 35rem;
        width: 35rem;
        //   background-color: #d3d7e2;
        transform: translate(50%, 0);

        .bg_color {
          background-color: #fcfcfc;
          // background-color: #e48989;
          border-radius: 50%;
          height: 100%;
          width: 100%;
        }

        .solid_text {
          position: absolute;
          left: 4rem;
          z-index: 9;
          writing-mode: vertical-lr;
          font-size: 3.2rem;
          white-space: nowrap;
        }

        @include mq("desktop") {
          // top: 10rem;
        }
      }
    }

    .title_with_image {
      background-color: $color-white-1;
      padding: 5rem;

      .content_holder {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .text_content {
        // min-width: 60rem;
        flex: 1;
        padding: 4rem 6rem;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        gap: 2.4rem;
        border-radius: 15px;
        box-shadow: 0 12px 42px -4px rgba(69, 69, 69, 0.12);
        background-color: #fff;
        height: 20rem;

        @include mq("tablet") {
          padding: 2rem 1.5rem;
        }

        .bg_color {
          position: absolute;
          left: 0;
          background-color: $color-secondary;
          border-radius: 50%;
          height: 20rem;
          width: 20rem;
          transform: translateX(-50%);
        }
        .img_holder {
        }
        h2 {
          font-size: 3.2rem;
          font-weight: 600;
          line-height: 1.13;
          letter-spacing: 0.75px;
          color: $color-black-2;
        }
      }

      .img_holder_style {
        min-width: max-content;
        position: relative;
        top: -1rem;
        z-index: 9;
        border-radius: 1.5rem;
        box-shadow: 0 5.2px 21.9px 0 rgba(69, 69, 69, 0.12),
          -2rem 2rem 0 0 $color-secondary;
        display: flex;
        align-items: center;
        width: 23rem;
        background-color: #f5f5f5;
        overflow: hidden;
      }

      @include mq("desktop") {
        padding: 5rem 2rem;

        .content_holder {
          flex-direction: column;
          gap: 4rem;
          .text_content {
            width: 100%;
          }
          .img_holder_style {
            order: -1;
          }
        }
      }
    }

    .about_artical_hero {
      padding: 5rem;

      @include mq("desktop") {
        padding: 5rem 2rem;
      }
      .artical_holder {
        max-width: 70rem;
        padding: 3rem 0;
        .logo_holder {
          //   min-width: 14rem;
        }
        .text_holder {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          h2 {
            font-size: 2rem;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.75px;
            color: $color-black-2;
          }
          p {
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.75px;
            color: $color-gray-2;
          }
        }
      }
    }
  }
}
