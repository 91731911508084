@import "../../styles/utilities";

.china_gate_page {
  padding-top: 5.6rem;

  @include mq("ipad") {
    padding-top: 7.5rem;
  }
  .china_gate_inner {
    background-image: url("../../public/images/china-gate-bg.png");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }
}

.cover_wrapper {
  // background-image: url("../../public/images/china-gate-bg.png");
  // background-repeat: no-repeat;
  // background-position: 50% 0;
  // background-size: cover;
  min-height: calc(100vh - 5.6rem);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mq("ipad") {
    min-height: calc(100vh - 7.5rem);
  }

  .cover_inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 4rem 2rem;

    .text_wrapper {
      .tooltip_info_wrapper {
        padding: 3rem 0;
        .tooltip_info_inner {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 1.5rem;
          // background-color: #3f3f3f81;
          .tooltip_hero {
            padding: 1px;
            border-radius: 50%;
            font-size: 3.4rem;
            color: #111;
            background-color: #fafafa;
          }
        }
      }
    }

    .places_wrapper {
      .places_list {
      }
    }
    .ADS_place {
      border: solid 4px #fff;
      border-radius: 10px;
      height: 20rem;
      width: 100%;
      max-width: 65rem;
      margin: auto;
      margin-top: 8rem;
      overflow: auto;
    }
  }
}

.btn_show_more {
  width: fit-content;
  margin: auto;
  height: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  span {
    font-size: 2rem;
    text-transform: capitalize;
    color: #f5f100;
  }

  svg {
    font-size: 3.5rem;
    color: #f5f100;
    transition: 0.3s linear;
  }

  &:hover {
    svg {
      animation: scroll 1s infinite;
    }
  }
}

.places_wrapper_holder {
  padding: 8rem 0;
  // background-image: url("../../public/images/china-gate-bg.png");
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
  background-attachment: fixed;
  min-height: calc(100vh - 5.6rem);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mq("ipad") {
    min-height: calc(100vh - 7.5rem);
  }
}

@keyframes scroll {
  from {
    transform: translateY(-4px);
  }
  to {
    transform: translateY(4px);
  }
}
