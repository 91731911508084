@import "../../../styles/utilities";

.statistics_wrapper {
  // height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  // padding-top: 20rem;
  position: relative;

  .solid_txt {
    position: absolute;
    top: 20%;
  }

  .cards_holder {
    width: 73rem;
    height: 60rem;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .statistics_card_holder {
      width: 27.6rem;
      height: 27.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 50%;
      position: absolute;
      h2 {
        font-weight: 600;
        font-size: 6rem;
        letter-spacing: 0.701112px;
        color: #ffffff;
        margin-bottom: 1.5rem;
      }
      p {
        font-weight: 600;
        font-size: 1.6rem;
        letter-spacing: 0.701112px;
        color: #ffffff;
      }
      &.inst {
        background-color: #b22222;
        // margin-right: -60px;
        left: 0;
        bottom: 0;
      }
      &.youtub {
        background-color: #c58e00;
        // margin-left: -60px;
        right: 0;
        bottom: 0;
      }
      &.facebook {
        background-color: #f5d200;
        width: 31.8rem;
        height: 31.8rem;
        // top: -140px;
        z-index: 2;
        p,
        h2 {
          color: #b22222;
        }
      }
    }

    @include mq("tablet-small") {
      flex-direction: column;
      height: auto;
      gap: 2rem;
      .statistics_card_holder {
        position: static;
      }
    }
  }
}
