@import "../utilities";

.hic_grop_page,
.apps_slider_holder {
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
    overflow: hidden;
    height: 230px;
  }
  .slick-slide {
    > div {
      height: 100%;
      display: flex;
      align-items: center;
      span {
        width: 100% !important;
      }
      .slide-holder {
        height: 100%;
      }
    }
  }
  .slider-brands-gallary {
    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide {
      height: 100%;
    }
    .slick-slide > div {
      margin: 0 5px;
      > div {
        height: 100%;
        .slide_content_holder {
          height: 100%;
        }
      }
      .image_holder {
        transition: none !important;
        transform: scale(1) !important;
        height: 100%;
        .ant-image {
          height: 100%;
          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  .slide-holder {
    // position: relative;
    // img {
    //   width: 100%;
    //   height: 100%;
    //   object-fit: cover;
    // }
  }

  .slick-arrow {
    width: max-content;
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 1.4rem 0.7rem;
    border-radius: 3px;
    background-color: $color-white-3;
    transition: 0.2s ease-in-out;
    top: 50%;

    svg {
      font-size: 1.7rem;
      color: $color-primary;
    }

    &::before {
      display: none;
    }

    &.slick-next {
      right: 0;
      //   left: 9rem;
      bottom: 0rem;
    }
    &.slick-prev {
      left: 0;
      bottom: 0;
    }

    &:lang(ar) {
      &.slick-next {
        right: 0;
        left: unset;
      }
      &.slick-prev {
        left: 0;
        right: unset;
      }
    }

    &:hover {
      background-color: $color-primary;
      svg {
        color: $color-white-1;
      }
    }
  }

  .image_holder {
    transition: 0.3s ease-in-out;
    img {
      height: 100%;
      object-fit: contain;
      padding: 0 10px;
      cursor: pointer;
    }
    &:hover {
      transform: scale(1.2);
    }
  }
}

// .apps_slider_holder .slick-slider, .apps_slider_holder .slick-list, .apps_slider_holder .slick-track, .apps_slider_holder .slick-slide{
//   overflow: hidden;
// }
